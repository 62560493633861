import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-primary-law',
  templateUrl: './primary-law.component.html',
  styleUrls: ['./primary-law.component.scss']
})
export class PrimaryLawComponent implements OnInit {

 

  constructor() { 

  }

  ngOnInit(): void {

  }

 



}
