<div class="full-width">
  <div class="row page-title">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <h3>البرامج</h3>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12">
      <nav aria-label="breadcrumb" class="titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
          <li class="breadcrumb-item" style="padding-left: 0px">
            <a routerLink="programs" style="padding-right: 10px">البرامج</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-10 col-xs-12 col-sm-12">
      <div class="row">
        <!-- make for loop to show programs -->
        <div
          class="col-md-4 col-sm-6 col-xs-12"
          *ngFor="let item of programs_list"
        >
          <div class="card">
            <img
              class="card-img"
              src="{{
                item.image ? item.image : '/assets/images/program1.png'
              }}"
              alt="card-image"
            />
            <h6 class="card-title">{{ item?.title }}</h6>
            <p class="card-paragraph">
              {{ item?.content | textFormatter }}
            </p>
            <a class="read-more" routerLink="{{ item.id }}"> اقرأ المزيد </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
