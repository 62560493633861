import { Component, OnInit } from '@angular/core';
import {CommonGeneralService} from '../services/common-general.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  privacypolicy;

  constructor(private service:CommonGeneralService) { }

  ngOnInit(): void {
    this.service.getPrivacyPolicy().subscribe((res:any)=>{
      this.privacypolicy = res.content[0]
      
    })
  }

}
