import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  currentUser = new BehaviorSubject('');
  currentToken = new BehaviorSubject('');
  isNotify = new BehaviorSubject(false);

  constructor() { }
}
