import { Component, OnInit } from '@angular/core';
import { PartenersService } from '../../services/parteners.service';


@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.scss']
})
export class BanksComponent implements OnInit {

  // banks lis empty object
  banks_list: any[] = [];

  pageSize: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;

  constructor(
    private service: PartenersService
  ) { }

  ngOnInit(): void {
    this.getBanks();
  }

  // get banks list data from api
  getBanks() {
    this.service.getBanksList(this.currentPage).subscribe((res: any) => {
      // push banks list to object
      this.banks_list = res.content;
    })
  }

}
