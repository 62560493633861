<div class="row">
  <div class="col-md-6 col-sm-6 col-xs-6">
    <h3 class="main-title">وقف الجمعية</h3>
  </div>
  <div class="col-md-6 col-sm-6 col-xs-6"></div>
</div>

<div class="slider">
  <div class="swiper">
    <div class="swiper-container" [swiper]="config">
      <div class="swiper-wrapper">
        <div *ngFor="let waqf of waqf_list" class="swiper-slide">
          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12 slide-text">
              <h4 class="main-title">
                {{ waqf.title }}
              </h4>
              <div class="main-paragraph">
                <p>
                  {{ waqf.content | textFormatter }}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
              <img
                class="program-img"
                height="360px"
                [src]="waqf.image"
                alt="waqf-image"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="swiper-pagination"
        slot="pagination"
      ></div>

      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</div>
