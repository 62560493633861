<div class="row" style="margin:5px 0px">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="card grey-bg">
            <h5> معايير اختيار مريض فشل كلوي للدخول إلى البرنامج الخدمات الطبية و العلاجية من برنامج رعاية</h5>
            <p>إجراء الغسيل الكلوي الدموي ثلاث مرات أسبوعياً بما يتوافق مع المقاييس العالمية لهذه الخدمة. كذلك تشمل العناية بالمآخذ الوعائية و المحافظة عليها، بما يضمن سلامتها.</p>
            <p>إعطاء جميع الأدوية التي يستوجب استخدامها أثناء غسيل الدم كالايثربويتين و المضادات الحيوية، و غيرها من الأدوية الأساسية.</p>
            <p>إجراء الفحوص الدورية المتكاملة و التي تجرى على فترات مختلفة حسب ما هو متوافق مع المقاييس الطبية، و من خلال هذه التحاليل يتم مراقبة الوضع الصحي للمريض.</p>
            <p>تغطية الأدوية الأساسية بين الغسلات كعلاجات ارتفاع ضغط الدم، و الداء السكري ، و ارتفاع الكولسترول.</p>
            <p>تغطية علاج الحالات الطبية الطارئة التي تحدث لمرضى الفشل الكلوي أثناء إجراء جلسات الغسيل الكلوي.</p>
            <p>تأمين خدمة النقل ( المواصلات ) لمرضى الفشل الكلوي من و إلى مركز الغسيل الكلوي.</p>
        </div>
    </div>
</div>