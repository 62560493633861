<div class="full-width">
  <div class="row page-title">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <h3>أوقاف الجمعية</h3>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12">
      <nav aria-label="breadcrumb" class="titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
          <li class="breadcrumb-item" style="padding-left: 0px">
            <a routerLink="../awqaf" style="padding-right: 10px">أوقاف الجمعية</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div class="container">
  <div class="row" style="margin: 30px 0px" *ngFor="let item of awqafList">
    <div class="col-md-7 col-sm-12 col-xs-12">
      <h6 class="main-title">{{ item.title }}</h6>
      <p>{{ item.content | textFormatter }}</p>
    </div>
    <!-- if slider or one image -->
    <div class="col-md-5 col-sm-12 col-xs-12">
      <!-- slider -->
      <div class="swiper">
        <div class="swiper-container" *ngIf="slides.length" [swiper]="config">
          <div class="swiper-wrapper">
            <div *ngFor="let slide of slides; index as i" class="swiper-slide">
              <div class="slide-img">
                <img [src]="slide.image" />
              </div>
            </div>
          </div>
          <div class="swiper-pagination" slot="pagination"></div>

          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
      <!-- image -->
      <div class="swiper">
        <div class="swiper-container" *ngIf="!slides.length">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="slide-img">
                <img [src]="item.image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
