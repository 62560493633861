<div class="row" style="margin:5px 0px">
    <div class="col-md-6 col-sm-6 col-xs-12">
        <div class="card">
            <h6 class="main-title" style="text-align: right;">شروط التقديم بالنسبة للمرضى السعوديين</h6>
            <p>عدد مرضى البرنامج الذين تم إجازتهم من اللجنة الشرعية منذ بداية إنشاء البرنامج أكثر من (1,500) مريض / مريضة.</p>
            <p>عدد المرضى الذين يتلقون العلاج حالياً (446) مريض/ مريضة, بتكلفة إجمالية تقدر بـــ (51,750,000) ريال سنوياً.</p>
            <p>عدد المرضى الذين على قائمة الانتظار لدى الجمعية (550) مريض / مريضة .</p>
            <p>معدل تكلفة المريض الواحد سنوياً شاملة خدمات الغسيل والأدوية (115,000) ريال.</p>
            <p>معدل تكلفة عمليات الوصلة الوريدية والوصلات المؤقتة والتنويم في المستشفيات (4,800,000) ريال سنويا.</p>            </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12">
        <div class="card">
            <h6 class="main-title" style="text-align: right;">شروط التقديم بالنسبة للمرضى غير السعوديين</h6>
            <p>عدد مرضى البرنامج الذين تم إجازتهم من اللجنة الشرعية منذ بداية إنشاء البرنامج أكثر من (1,500) مريض / مريضة.</p>
            <p>عدد المرضى الذين يتلقون العلاج حالياً (446) مريض/ مريضة, بتكلفة إجمالية تقدر بـــ (51,750,000) ريال سنوياً.</p>
            <p>عدد المرضى الذين على قائمة الانتظار لدى الجمعية (550) مريض / مريضة .</p>
            <p>معدل تكلفة المريض الواحد سنوياً شاملة خدمات الغسيل والأدوية (115,000) ريال.</p>
            <p>معدل تكلفة عمليات الوصلة الوريدية والوصلات المؤقتة والتنويم في المستشفيات (4,800,000) ريال سنويا.</p>            </div>
    </div>
</div>