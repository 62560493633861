import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthenticationService } from "../authentication/services/authentication.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-register-verification",
  templateUrl: "./register-verification.component.html",
  styleUrls: ["./register-verification.component.scss"],
})
export class RegisterVerificationComponent implements OnInit {
  CodeForm: FormGroup = new FormGroup({
    code: new FormControl(null, [Validators.required]),
  });

  opened2: boolean;
  constructor(
    private route: Router,
    private AuthenticationService: AuthenticationService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {}
  submitForm() {
    let body = {
      email: localStorage.getItem("email"),
      confirm_verification_code: this.CodeForm.value.code,
      email_verification_code: this.CodeForm.value.code,
    };
    this.AuthenticationService.sendVerificationCode(body).subscribe(
      (res) => {
        this.route.navigateByUrl("/new-password");
      },
      (err) => {
        this.toaster.error(err.error.status.error_details);
      }
    );
  }
}
