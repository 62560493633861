<div class="row">
    <div class="col-md-3 col-sm-6 col-xs-12 pull-left">
        <div class="card text-center">
            <img class="" src="{{vision?.photo}}" height="150" >
            <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ vision?.photo +')'}"></div> -->
            <h2 class="main-title text-center">رؤيتنا</h2>
            <p class="card-body" >
                جعل المجتمع أكثر وعياً بمخاطر الفشل الكلوي و بالعوامل المسببة له وتعزيز قدرة القطاع الصحي لتلبية احتياجات مرضى الفشل الكلوي ووضع الخطط اللازمة لتأمين كوادر مؤهلة من الاختصاصيين و الفنيين السعوديين وتوفير البرامج الاجتماعية التي تقدم العون لمرضى الفشل الكلوي والمساهمة في إعداد الأبحاث العلمية النشطة في مجالات الوقاية و العلاج والعمل على تبني و قبول أوسع لفكرة التبرع بالكلى بين الجمهور.

            </p>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12  pull-left">
            <div class="card text-center">
                <img class="" src="{{mission?.photo}}" height="150" >
                <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ mission?.photo +')'}"></div> -->
                <h2 class="main-title text-center">رسالتنا</h2>
                <p class="card-body" >
                    المساهمة في تقديم الخدمات الصحية و الاجتماعية و الوقائية لمرضى الفشل الكلوي و دعم الأبحاث العلمية و برامج التبرع بالأعضاء و التوعية الصحية لأفراد المجتمع.                </p>
            </div>
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12 pull-left">
        <div class="card text-center">
            <img class="" src="{{goals?.photo}}" height="150" >
            <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ goals?.photo +')'}"></div> -->
            <h2 class="main-title text-center">هدفنا</h2>
            <p class="card-body right-text" >
                تهدف الجمعية إلى تقديم خدماتها دون أن يكون هدفها الربح المادي و تشمل هذه الخدمات مساعدة مرضى الفشل الكلوي المحتاجين, الإسهام في تأمين الأجهزة و الأدوية و المستلزمات الطبية الخاصة بالفشل الكلوي, تشجيع ودعم البحث العلمي وبرامج التوعية الخاصة بأمراض الكلى وزراعتها ودعم برامج التبرع بالأعضاء.
            </p>
        </div>
    </div>

    <div class="col-md-3 col-sm-6 col-xs-12 pull-left">
        <div class="card text-center">
            <img class="" src="assets/images/ta2sis.jpg" height="150"  style="width: 45%;height: 178px;margin-top: 23px;margin-bottom: 20px;">
            <!-- <div class="img-responsive" [ngStyle]="{'background-image': 'url('+ vision?.photo +')'}"></div> -->
            <h2 class="main-title text-center">التأسيس</h2>
            <p class="card-body" >
                جمعية الأمير فهد بن سلمان الخيرية لرعاية مرضى الفشل الكلوي تأسست طبقاً لأحكام لائحة الجمعيات و المؤسسات الخيرية الصادرة بقرار مجلــس الوزراء بتاريخ 1410/6/25هـ و قواعدها التنفيذية الصادرة بقرار وزير العمل و الشؤون الاجتماعية تشمل خدمات الجمعية كافة مناطق المملكة حيث مقر المركز الرئيس في مدينة الرياض.
            </p>
        </div>
    </div> 
</div>