import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {

  registrationForm: FormGroup;

  UserInfo;

  UserData;


  oldPassword: any = 'password';

  password: any = 'password';

  confirmPassword: string = 'password';


  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.registrationForm = formBuilder.group({
      old_password: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$')])],
      confirm_password: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$')])],
    },
      { validators: this.passwordMatching }
    )
  }

  ngOnInit(): void {
    this.UserInfo = localStorage.getItem('user_info');
    this.UserData = JSON.parse(this.UserInfo)
  }

  changoldPasswordVisiabilty() {
    if (this.oldPassword == 'password') {
      this.oldPassword = 'text'
    } else {
      this.oldPassword = 'password'
    }
  }


  changPasswordVisiabilty() {
    if (this.password == 'password') {
      this.password = 'text'
    } else {
      this.password = 'password'
    }
  }

  changConfirmPasswordVisiabilty() {
    if (this.confirmPassword == 'password') {
      this.confirmPassword = 'text'
    } else {
      this.confirmPassword = 'password'
    }
  }

  passwordMatching(form: AbstractControl) {
    if (form.get('confirm_password').errors && !form.get('confirm_password').errors.notMatch) {
      // return if another validator has already found an error on the confirmation control
      return;
    }
    if (form.get('confirm_password').value !== form.get('password').value) {
      form.get('confirm_password').setErrors({ notMatch: true })
      return { invalid: true }
    } else {
      form.get('confirm_password').setErrors(null)
      return;
    }
  }



  submit(value: any) {

    if (value.invalid && value.pristine) {
      value.markAllAsTouched()
    } else if (value.invalid && value.dirty) {
      Object.keys(value.controls).forEach(field => {
        const control = value.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      this.toastr.error('من فضلك ادخل باقي البيانات');

    } else if (value.valid == true) {
      this.changePassword();
    }
  }


  changePassword() {

    let data = {
      "old_password": this.registrationForm.value['old_password'],
      "password": this.registrationForm.value['password'],
      "password_confirmation": this.registrationForm.value['confirm_password'],
    }
    this.authService.updateProfileForm(data).subscribe((res: any) => {

      if (res.status.code === 200) {
        this.toastr.success('تم بنجاح');

        this.router.navigateByUrl('/profile');
      }
      else if (res.status.code === 400) {
        this.toastr.error(res.status.error_details);
      }
      else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);
      }
      else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        validation_error.forEach(element => {

          if (element.field == 'old_password') {
            this.toastr.error(element.message);
          }

          if (element.field == 'password') {
            this.toastr.error(element.message);
          }

          else if (element.field == 'password_confirmation') {
            this.toastr.error(element.message);
          }

        })

      }
    })
  }

}
