import { Component, OnInit } from "@angular/core";

import { VolunteeringService } from "../../services/volunteering.service";

@Component({
  selector: "app-volunteer",
  templateUrl: "./volunteer.component.html",
  styleUrls: ["./volunteer.component.scss"],
})
export class VolunteerComponent implements OnInit {

  // volunteer empty object
  volunteer: any = {};

  constructor(
    private volunteerService: VolunteeringService
  ) { }

  ngOnInit(): void {
    // get volunteer data from api
    this.volunteerService.getVolunteerText().subscribe((res: any) => {
      this.volunteer = res.content[0];
    });
  }
}
