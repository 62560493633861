<div class="full-width">
  <div class="page-title">
    <div class="container">
      <h3>
        نتائج البحث
      </h3>
    </div>
  </div>
</div>
<!-- <div class="container">
        <h3 class="main-title text-left"> الفعاليات   </h3>
        <div class="row card">
            <div *ngFor="let event of eventList" class="col-md-4 col-sm-6 col-xs-12">
                <app-card-event [eventDetails]="event"></app-card-event>
            </div>
        </div>
        <h3 *ngIf="eventList && !eventList.length" class="main-title text-center">لا يوجد نتائج</h3>
    </div> -->
<div class="container">
  <h3 class="main-title text-left"> الأخبار </h3>
  <div class="row card ">
    <div *ngFor="let article of articles" class="col-md-4 col-sm-4 col-xs-12">
      <app-article-card [article]="article"></app-article-card>
    </div>
  </div>
  <h3 *ngIf="articles && !articles.length" class="main-title text-center">لا يوجد نتائج</h3>
</div>
<!-- <div class="container">
        <h3 class="main-title text-left"> مبادرات الصندوق  </h3>
        <div class="row card">
            <div *ngFor="let initiative of initiatives" class="col-md-4 col-sm-6 col-xs-12">
                <a [routerLink]="[initiative.id]" class="feature">
                    <div class="initiative-img">
                        <img [src]="initiative.image" class="img-responsive" alt="">
                    </div>
                    <div class="initiative-desc">
                        <h4 class="text-center ">{{initiative.name}}</h4>
                    </div>
                </a>
            </div>
        </div>
        <h3 *ngIf="initiatives && !initiatives.length" class="main-title text-center">لا يوجد نتائج</h3>

    </div> -->
