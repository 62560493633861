<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3>
            Our Partners
        </h3>
        </div>
    </div>
</div>
<div class="event_details">
    <div class="container">
        <div class="row">
            <div *ngFor="let partner of partners" class="col-md-2 offset-md-1 col-sm-3 col-xs-4 pull-left">
                <div class="card">
                    <img [src]="partner.image" class="img-responsive company-logo" alt="partner.name">
                </div>
            </div>

        </div>
    </div>
</div>
