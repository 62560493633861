<div class="card" *ngFor="let service of services_list">
  <div
    class="card_image"
    [class.icon]="service.imageType == 'icon'"
    [class.image]="service.imageType != 'icon'"
  >
    <img
      [src]="service.icon ? service.icon : '/assets/images/notebook.svg'"
      alt="service-image"
    />
  </div>
  <div class="card_content">
    <div>
      <label class="service_name">{{
        service.title ? service.title : service.form.form_name
      }}</label>
      <button
        *ngIf="service.form.entity_id"
        type="button"
        class="btn btn-primary"
        data-toggle="tooltip"
        data-placement="bottom"
        title="عرض"
        routerLink="/programs/{{ service.form.entity_id }}"
      >
        <i class="fa fa-eye"></i>
      </button>
      <button
        type="button"
        class="btn btn-primary text-primary"
        data-toggle="tooltip"
        data-placement="bottom"
        title="تعديل"
        (click)="
          navigateToEditPage(
            type == 'program' ? service.form_id : service.id,
            type == 'program' ? service.id : ''
          )
        "
      >
        <i class="fa fa-edit"></i>
      </button>
      <!-- <button
        type="button"
        class="btn btn-danger"
        data-toggle="tooltip"
        data-placement="bottom"
        title="حذف"
      >
        <i class="fa fa-trash"></i>
      </button> -->
    </div>
    <div class="program-desc" *ngIf="type == 'program'">
      <p [innerHTML]="service.form.description"></p>
    </div>
    <div class="sub_title_wrapper">
      <label class="sub_title">حالة</label>
      <p [class]="service.type">
        {{
          service.membership_status?.name
            ? service.membership_status?.name
            : "قيد المراجعة"
        }}
      </p>
    </div>
    <div class="sub_title_wrapper">
      <label class="sub_title">تاريخ تقديم الطلب</label>
      <p class="date">
        {{
          service.created_at ? service.created_at : "غير متوفر في الوقت الحالي"
        }}
      </p>
    </div>
  </div>
</div>
