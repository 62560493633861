import { Component, OnInit } from "@angular/core";
import { PartenersService } from "../../services/parteners.service";

@Component({
  selector: "app-charities",
  templateUrl: "./charities.component.html",
  styleUrls: ["./charities.component.scss"],
})
export class CharitiesComponent implements OnInit {

  // charities list empty object
  charities_list: any[] = [];

  pageSize: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;

  constructor(
    private service: PartenersService
  ) { }

  ngOnInit(): void {
    this.getCharities();
  }

  // get charities list data from api
  getCharities() {
    this.service.getCharitiesList(this.currentPage).subscribe((res: any) => {
      // push charities data to object
      this.charities_list = res.content;
    });
  }
}
