<div class="row">

  <div *ngFor="let partner of partners_list| slice:0:4;" class="col-md-3 col-sm-4 col-xs-12" style="margin-bottom: 20px;">
    <a>
      <img class="partner-img" [src]="partner.image">
    </a>

  </div>

</div>
