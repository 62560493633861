<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3>{{'GENERAL_PAGES.TEAM.BOARD_MEMBER'|translate}}
                <small>{{'GENERAL_PAGES.TEAM.ITERATION_THREE'|translate}} (2020-2021)</small>
            </h3>
        </div>
    </div>
</div>

<div class="internal-page">
    <div class="container">
        <div class="row">
                <div class="m-auto">
                    <app-team-member [team]="teams[0]" class="m-auto"></app-team-member>
                </div>
        </div>
        <div class="row">
                <div class="m-auto">
                    <app-team-member [team]="teams[1]" class="m-auto"></app-team-member>
                </div>
        </div>
        <div class="row" >
            <div *ngFor="let team of teams" class="pull-left">
                <app-team-member [team]="team" class="m-auto"></app-team-member>
            </div>
        </div>
    </div>
</div>




