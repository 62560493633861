import { Component, OnInit } from "@angular/core";

// import about services
import { AboutService } from "../../services/about.service";

@Component({
  selector: "app-coc",
  templateUrl: "./coc.component.html",
  styleUrls: ["./coc.component.scss"],
})
export class CocComponent implements OnInit {
  // set COC empty object
  COC: any = {};
  constructor(private AboutService: AboutService) { }

  ngOnInit(): void {
    // call api to get COC data
    this.AboutService.getCOC().subscribe((res: any) => {
      // push COC data to COC object
      this.COC = res.content;
    });
  }
}
