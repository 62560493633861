<div class="row">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-6">
        <h3 class="main-title">البرامج</h3>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6">
        <a class="all-btn" routerLink="/programs">الكل</a>
      </div>
    </div>

    <div class="row">
      <div *ngFor="let p of programs" class="col-md-4 col-sm-6 col-xs-12">
        <div class="card">
          <img class="card-img" [src]="p.media[0]?.image" />
          <h4 class="card-title text-dark">{{ p.title }}</h4>
          <p class="card-paragraph" >
            {{ p.content | textFormatter }}
          </p>
          <a class="read-more" routerLink="/programs/{{ p.id }}"> قدم الآن </a>
        </div>
      </div>
    </div>
  </div>
</div>
