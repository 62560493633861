<a [routerLink]="[statement.id]">

  <div class="card">
    <div class="row">
      <div class="col-md-4 col-sm-4 col-xs-12">

        <!-- if media is empty -->
        <div *ngIf="statement?.media == ''">
          <img class="card-img-top" src="{{ statement?.image }}">
        </div>

        <!-- else if media not empty -->
        <div *ngIf="statement?.media !== ''">
          <img *ngFor="let file of statement?.media" class="card-img-top" src="{{ file?.image }}">
        </div>

      </div>
      <div class="col-md-8 col-sm-8 col-xs-12">
        <h3 class="card-title">{{statement.title}}</h3>
        <!-- <p class="p-date">{{statement.start_date}}</p> -->
        <p class="p-date">{{statement.start_date_hijri}}</p>
        <p>{{ statement.content | textFormatter}}</p>
        <button class="red-btn"> إقرأ المزيد</button>
      </div>

    </div>
  </div>
</a>
