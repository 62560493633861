<div class="slider ">
    <div class=" swiper">
        <div class="swiper-container" *ngIf="slides.length" [swiper]="config">
            <div class="swiper-wrapper">
                <div *ngFor="let slide of slides; index as i" class="swiper-slide">
                    
                    <div class="slide-img">
                        <img *ngIf="slide.type == 'image' " [src]="slide.image">
                     
                       
                            
                        <iframe *ngIf="slide.type == 'video'" width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(slide.link))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        
                          
                        <!-- <div *ngIf="slide.type == 'video'" [innerHtml]="sanitizer.bypassSecurityTrustResourceUrl(yt_iframe_html)"></div> -->
                        <!-- <div *ngIf="slide.type == 'video'" src="https://vimeo.com/441479107/embed"></div> -->
                        <!-- <iframe *ngIf="slide.type == 'video'" width="100%" height="100%"src="https://player.vimeo.com/video/441479107" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

                    </div>

                    <!-- <div class="slide-text text-left">
                        <h2> {{slide.title}} </h2>
                        <p> {{slide.description}}</p>
                        <a (click)="goTo(slide.link)" class="btn-custom-sec rounded20"> اقرأ المزيد</a>
                    </div> -->
                </div>

                
            </div>
            <!-- <div class="swiper-scrollbar" [hidden]="config.scrollbar === false"></div> -->
            <div class="swiper-pagination" slot="pagination" ></div>

            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>

</div>