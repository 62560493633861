import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  opened=false;
  @Output() closePopup = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  close(event)
  {
    this.opened=false; 

    event.preventDefault();

   }
   valueChanged() { 
    this.opened=false;
    this.closePopup.emit(this.opened);

  }

}
