import { Component, OnInit } from "@angular/core";

// import about services
import { AboutService } from "../../services/about.service";

@Component({
  selector: "app-stratigic",
  templateUrl: "./stratigic.component.html",
  styleUrls: ["./stratigic.component.scss"],
})
export class StratigicComponent implements OnInit {

  // set strategic empty object
  stratigic: any = {};

  // set goals empty object
  goals: any = {};

  constructor(
    // constructor about services
    private AboutService: AboutService
  ) { }

  ngOnInit(): void {

    // call api to get strategic
    this.AboutService.getstratigic().subscribe((res: any) => {
      // push strategic data to strategic object
      this.stratigic = res.content;
    });

    // call api to get goals
    this.AboutService.getGoals().subscribe((res: any) => {
      // push goals data to goals object
      this.goals = res.content;
    });
  }
}
