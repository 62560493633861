<div class="row">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-6">
        <h3 class="main-title">رؤساء إدارة الجمعية</h3>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6">
      </div>

    </div>

  </div>
</div>

<div class="row">
  <div *ngFor="let manager of managers_list| slice:0:3;" class="col-md-4 col-sm-12 col-xs-12 mb-4">

    <div class="home-article">
      <a class="cover-img" [style]="{ 'background-image': 'url(' + manager.image + ')'}">
        <div class="home-article-desc text-left">
          <h4 class="card-title">{{manager.title}}</h4>
          <p class="card-paragraph">
            {{manager.content | textFormatter}} </p>
        </div>
      </a>
    </div>

  </div>
  <!-- <div class="col-md-4 col-sm-12 col-xs-12">

    <div class="home-article">
      <a class="cover-img" style="background-image: url(assets/images/minister.jpg);">
        <div class="home-article-desc text-left">
          <h4 class="card-title">صاحب السمو الملكي الأمير عبدالعزيز بن سلمان </h4>
          <p class="card-paragraph">
            رئيس مجلس الإدارة
          </p>


        </div>
      </a>
    </div>

  </div>
  <div class="col-md-4 col-sm-12 col-xs-12">

    <div class="home-article">
      <a class="cover-img" style="background-image: url(assets/images/about1.jpg);">
        <div class="home-article-desc text-left">
          <h4 class="card-title">الأستاذ الدكتور عبد الله بن حسن الدغيثر
          </h4>
          <p class="card-paragraph">
            الرئيس التنفيذي

          </p>


        </div>
      </a>
    </div>

  </div> -->
</div>
