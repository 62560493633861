<a [routerLink]="[article.id]">
  <div class="card">
    <div class="row">
      <div class="col-md-4 col-sm-4 col-xs-12">
        <img
          class="card-img-top"
          src="{{ article?.media ? article?.media[0]?.image : article?.image }}"
          alt="article-image"
          height="160px"
          width="160px"
        />
      </div>
      <div class="col-md-8 col-sm-8 col-xs-12">
        <h3 class="card-title">{{ article.title }}</h3>
        <!-- <p class="p-date">{{article.start_date}}</p> -->
        <p class="p-date">{{ article.start_date_hijri }}</p>
        <p>
          {{ article.content | textFormatter }}
        </p>
        <button class="red-btn">إقرأ المزيد</button>
      </div>
    </div>
  </div>
</a>
