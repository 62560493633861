<div class="container-fluid">
    <div class="row" *ngIf="!isVerify">
        <div class="col-md-12 col-sm-12 col-xs-12 login_bar">
            <h2>{{ "AUTH.REGISTER.REGISTRATION" | translate }}</h2>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 mt-5 mb-5 login_wrapper">
            <div class="col-md-5 col-sm-12 col-xs-12 empty_wrapper"></div>
            <div class="col-md-7 col-sm-12 col-xs-12 form_wrapper">
                <h2>{{ "AUTH.REGISTER.REGISTRATION" | translate }}</h2>
                <form [formGroup]="registrationForm" (ngSubmit)="submitForm()">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="full_name" [placeholder]="'Username' | translate" />
                        <div *ngIf="
                registrationForm.get('full_name').touched &&
                registrationForm.get('full_name').invalid
              ">
                            <div *ngIf="registrationForm.get('full_name').errors.required" class="text-right validator-error">
                                {{ "VALIDATORS.REQUIRED" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" formControlName="email" [placeholder]="'AUTH.REGISTER.EMAIL' | translate" />
                        <div *ngIf="
                registrationForm.get('email').touched &&
                registrationForm.get('email').invalid
              ">
                            <div *ngIf="registrationForm.get('email').errors.required" class="text-right validator-error">
                                {{ "VALIDATORS.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="
                  registrationForm.get('email').invalid &&
                  !registrationForm.get('email').errors.required
                " class="text-right validator-error">
                                {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="mobile" [placeholder]="'AUTH.REGISTER.PHONE_NUMBER' | translate" />
                        <div *ngIf="
                registrationForm.get('mobile').touched &&
                registrationForm.get('mobile').invalid
              ">
                            <div *ngIf="registrationForm.get('mobile').errors.required" class="text-right validator-error">
                                {{ "VALIDATORS.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="
                  registrationForm.get('mobile').invalid &&
                  !registrationForm.get('mobile').errors.required
                " class="text-right validator-error">
                                {{ "VALIDATORS.phone" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" formControlName="password" [placeholder]="'AUTH.NEW_PASSWORD.password' | translate" />
                        <div *ngIf="
                registrationForm.get('password').touched &&
                registrationForm.get('password').invalid
              ">
                            <div *ngIf="registrationForm.get('password').errors?.required" class="text-right validator-error">
                                {{ "VALIDATORS.REQUIRED" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" formControlName="confirm_password" [placeholder]="'AUTH.NEW_PASSWORD.confirm_password' | translate" />
                        <div *ngIf="registrationForm.get('confirm_password').touched">
                            <div *ngIf="
                  registrationForm.get('confirm_password').errors?.required
                " class="text-right validator-error">
                                {{ "VALIDATORS.REQUIRED" | translate }}
                            </div>
                            <div *ngIf="
                  registrationForm.get('confirm_password').errors?.mustMatch
                " class="text-right validator-error">
                                {{ "VALIDATORS.must_match" | translate }}
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-xs btn-custom-sec new" [disabled]="!registrationForm.valid" type="submit">
            {{ "AUTH.REGISTER.create_account" | translate }}
          </button>
                    <div class="register">
                        <p>{{ "AUTH.REGISTER.have_account" | translate }}</p>
                        <a [routerLink]="['/login']">{{
              "AUTH.LOGIN.login" | translate
            }}</a>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="isVerify">
        <div class="login_bar">
            <h2>رقم التاكيد</h2>
        </div>
        <div class="login_wrapper">
            <div class="empty_wrapper"></div>
            <div class="form_wrapper">
                <h2>الرجاء ادخال رقم التاكيد</h2>
                <form [formGroup]="verifyForm" (ngSubmit)="verify()">
                    <div class="form-group">
                        <input type="text" formControlName="email" class="form-control" placeholder="البريد الاليكتروني" />
                    </div>
                    <div class="form-group">
                        <input formControlName="code" type="text" class="form-control" placeholder="رقم التاكيد" />
                    </div>
                    <button class="btn btn-xs btn-custom-sec new" [disabled]="!verifyForm.valid" type="submit">
            تاكيد
          </button>
                </form>
            </div>
        </div>
    </div>
</div>