<div class="row">
    <div class="col-md-3 col-sm-6 col-xs-12" style="text-align: center;padding-bottom: 30px;">
        <img class="circle-img" src="assets/images/member1.jpg">
        <h6 class="names" style="text-align: center;"> عضو عامل</h6>
         <p class="member-paragraph">و هو العضو الذي شارك في تأسيس الجمعية أو انضم إليها بعد تأسيسها بناء على دعوة من مجلس الإدارة ، ويكون لهذا العضو حق حضور اجتماعات الجمعية العمومية فور انضمامه، كما يكون له حق التصويت على قراراتها وترشيح نفسه لعضوية مجلس الإدارة وذلك بعد مضي سنة من تاريخ انضمامه للجمعية ويدفع اشتراكاً سنوياً قدره ( 1000 ) ريال كحد أدنى .</p>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12" style="text-align: center;padding-bottom: 30px;">
        <img class="circle-img" src="assets/images/member2.jpg">
        <h6 class="names" style="text-align: center;"> عضو منتسب</h6>
         <p class="member-paragraph">هو العضو الذي يطلب الانتساب إلى عضوية الجمعية و يقبل ذلك مجلس الإدارة بعد تحقق الشروط المنصوص عليها بالمادة رقم ( 4 ) عدا شرط السن ، و يجوز حضوره اجتماعات الجمعية العمومية دون التصويت على قراراتها و لا الترشيح لعضوية مجلس الإدارة و يدفع اشتراكاً سنوياً مخفضاً مقداره ( 200 ) ريال كحد أدنى .</p>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12" style="text-align: center;padding-bottom: 30px;">
        <img class="circle-img" src="assets/images/member3.jpg">
        <h6 class="names" style="text-align: center;"> عضو فخري</h6>
         <p class="member-paragraph">ج – عضو فخري : هو العضو الذي تمنحه الجمعية العمومية العضوية الفخرية بناء على اقتراح المجلس و ذلك نظير جهوده المادية أو المعنوية أو العلمية في سبيل تحقيق أهداف الجمعية و له عند حضوره اجتماعات الجمعية العمومية و مجلس الإدارة حق المناقشة دون التصويت أو الترشيح و لا يثبت بحضوره صحة الانعقاد.</p>
    </div>
    <div class="col-md-3 col-sm-6 col-xs-12" style="text-align: center;padding-bottom: 30px;">
        <img class="circle-img" src="assets/images/member4.jpg">
        <h6 class="names" style="text-align: center;"> عضو شرف</h6>
         <p class="member-paragraph">هو العضو الذي يمنحه مجلس الإدارة عضوية الجمعية نظير ما قدمه لها من خدمات جليلة مادية كانت أم معنوية ساعدت الجمعية على تحقيق أهدافها و له حق حضور اجتماعات الجمعية العمومية ومناقشة ما يطرح فيها دون أن يكون له حق التصويت أو الترشيح لعضوية مجلس الإدارة . و لا يثبت بحضوره صحة الانعقاد .</p>
    </div>
</div>