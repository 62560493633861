<div class="row">
    <div class="col-md-4 col-sm-12 col-xs-12">
        <img class="program-img" src="assets/images/program.jpg">
    </div>
    <div class="col-md-8 col-sm-12 col-xs-12">
        <h3 class="main-title">برنامج رعاية غسيل دموي لمرض الفشل الكلوي</h3>
        <p class="main-paragraph">تم توقيع مذكرة تفاهم بين الشركة الوطنية للتأمين التعاوني و جمعية الأمير فهد بن سلمان الخيرية لرعاية مرضى الفشل الكلوي على قيام الشركة الوطنية للتأمين التعاوني بإدارة مشروع (برنامج رعاية /غسيل دموي لمرضى الفشل الكلوي المحتاجين) طبياً و فنياً و مالياً، من خلال التعاقد مع المستشفيات (مقدمي خدمة الغسيل الكلوي الدموي) لتأمين جلسات الغسيل الكلوي، و إجراء التحاليل الطبية اللازمة لكل جلسة و علاج الاختلاطات الطارئة التي تحدث للمريض أثناء جلسة الغسيل.

            أ - تأمين الخدمات الطبية الشاملة والخدمات المساندة المتميزة للمرضى المحتاجين :
            
            من خلال تأمين خدمة الغسيل الكلوي الدموي بواقع ثلاث إلى أربع جلسات أسبوعياً للمرضى المحتاجين ، الذين لا يتوفر لهم العلاج وتوفير الأدوية الأساسية التي يحتاجها المريض قبل وأثناء وبعد جلسات الغسيل وكذلك العمليات الجراحية والتنويم إذا لزم الأمر، وتوفير العلاجات اللازمة لحالات الطوارئ التي تحدث للمريض/ المريضة أثناء جلسات الغسيل الكلوي.
            يأتي ذلك في إطار تقديم أفضل الخدمات لمرضى الفشل الكلوي والتي بدأت منذ 1 / 1 / 1428 هـ بتقديم برنامج رعاية الغسيل الكلوي للمرضى المحتاجين, وهو البرنامج الأكثر كلفة على الجمعية حيث تبلغ متوسط التكلفة السنوية للمريض الواحد ( 115,000) ريال.
            
            ب - الاستفادة من الإمكانيات والخبرات المتوفرة في القطاع الخاص والجمعيات الخيرية لخدمة مرضى الفشل الكلوي المحتاجين بأقل التكاليف الممكنة.</p>
    </div>
</div>