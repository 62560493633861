import { MenuService } from './../../../services/General/menu.service';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
// import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { donationPlan } from '../../models/donation-plan';
import { HomeService } from './home.service';
import { CommonGeneralService } from '../services/common-general.service';
import { Router } from '@angular/router';

import TwitterTimelineData from './TwitterTimelineData';
import TwitterTimelineOptions from './TwitterTimelineOptions';

import { CarouselModule } from 'primeng/carousel';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  iframe_html: any;
  youtubeUrl = "https://www.youtube.com/watch?v=iHhcHTlGtRs";
  youtubeId = "iHhcHTlGtRs";
  video_array: any[] = [];

  donationCards: Array<donationPlan> = [];
  rtlRef = false;
  customOptions: OwlOptions;
  awards: OwlOptions;
  // news: any[] = [];
  events: any[] = [];
  whoWeAre: any = {};
  // slides: any[] = [];
  initiatives: any[] = [];
  boxSupport: string = "";
  associations: any[];
  test_link;

  // private pagination: SwiperPaginationInterface = {
  //   el: '.swiper-pagination',
  //   clickable: true
  // };
  // config: SwiperConfigInterface = {
  //   a11y: true,
  //   direction: 'horizontal',
  //   slidesPerView: 1,

  //   navigation: true,
  //   pagination: this.pagination,
  //   // grabCursor: true,

  //   effect: 'slide',
  //   speed:1500,
  // };
  maincardtitle: any;
  maincardinfo: any;
  maincardimg: any;
  selectedDistrictID: any;
  // associations: any;
  associationlist: any;

  @Input() data: TwitterTimelineData;
  /**
   * A hash of additional options to configure the widget
   */
  @Input() opts: TwitterTimelineOptions;
  defaultOpts: TwitterTimelineOptions;
  defaultData: TwitterTimelineData = {
    sourceType: "url",
    url: "https://twitter.com/@quran5051",
    screenName: "@quran5051",
  };
  // twttr.widgets.createTimeline(
  //   {
  //     sourceType: "profile",
  //     screenName: "TwitterDev"
  //   },
  //   document.getElementById("container")
  // );

  // ===================== Dynamic Home Parameters ===================
  show;

  slides: any = [];
  programs: any = [];
  managers_list: any = {};
  waqf_list: any = [];
  partners_list: any = {};
  news_list: any = [];
  news: any = {};
  statistics: any = {};

  constructor(
    private homeService: HomeService,
    private commonGeneralService: CommonGeneralService,
    private router: Router,
    private embedService: EmbedVideoService,
    public sanitizer: DomSanitizer,
    private servvise: MenuService
  ) {}
  ngOnInit(): void {
    
    if(localStorage.getItem('slides')!=null && localStorage.getItem("slides").length >0)
      {
        this.slides=JSON.parse(localStorage.getItem("slides"));
      }
    this.homeService.getHomeContent().subscribe((res: any) => {
      if (res.content) {
        const data = res.content;
        console.log("data ",data);
        
        for (let i = 0; i < data.length; i++) {
          // get programs
          if (data[i].title == "البرامج") {
            for (let x = 0; x < data[i].data.length; x += 4) {
              this.programs.push(data[i].data[x]);
            }
            // get slides
            if(this.slides.length == 0)
            {
              for (let z = 0; z < data[i].data.length; z++) {
              this.slides.push(data[i].data[z]);
              
            };
            localStorage.setItem("slides",JSON.stringify(this.slides));
            }
            
            // get managers
          } else if (data[i].title == "رؤساء إدارة الجمعية") {
            this.managers_list = data[i].data;
            // get waqf list
          } else if (data[i].title == "الوقف") {
            for (let y = 0; y < data[i].data.length; y++) {
              this.waqf_list.push(data[i].data[y]);
            }
            // get partner list
          } else if (data[i].title == "شركاؤنا") {
            this.partners_list = data[i].data;
            // get news
          } else if (data[i].title == "الاخبار") {
            for (let t = 0; t < data[i].data.length; t += 2) {
              if (data[i].data[t] == data[i].data[0]) {
                this.news = data[i].data[t];
              } else if (data[i].data[t] !== data[i].data[0]) {
                this.news_list.push(data[i].data[t]);
              }
            }
            // get statistics
          } else if (data[i].title == "احصائيات عامه") {
            this.statistics = data[i].data;
          }
        }
      }
    });

    // this.homeService.getHomeSlider().subscribe((res: any) => {
    //   this.slides = res.content;
    //   this.test_link = this.slides[0].link;
    //   
    //   // this.youtubeUrl = this.slides[0].link;
    //   // this.config.loop = true;
    //   // this.config.autoplay = true;
    //   // 
    //   // while (this.slides.length > 0)
    //   //  {
    //   //   if(this.slides['type']== 'video'){
    //   //     this.video_array.push(this.slides['link']);
    //   //     
    //   //   }
    //   // }

    // });
    // this.homeService.getNews().subscribe((res: any) => {
    //   this.news = res.content;
    //   // console.log ("el a5bar api : ",this.news)
    // })
    // this.homeService.getEvents().subscribe((res: any) => {
    //   this.events = res.content;
    // })
    // this.commonGeneralService.getWhoWeAre().subscribe((res: any) => {
    //   this.whoWeAre = res.content[0];
    // })

    // this.homeService.getInitiatives().subscribe((res: any) => {
    //   this.initiatives = res.content;
    // });
    // this.homeService.getSupportData().subscribe((res:any) => {
    //   this.boxSupport = res.content[0].content;
    // })
    // this.homeService.getAssociations().subscribe((res:any) => {
    //   this.associations = res.content.slice().sort((a, b) => {
    //     return (a['competition_order'] - b['competition_order']);
    //   })
    // })
    // for (let i = 0; i < 8; i++) {
    //   this.donationCards.push(
    //     {
    //       "price": "123SAR",
    //       "title": `donation${i}`,
    //       "features": ["feature1", "feature2", "feature3", "feature4"]
    //     }
    //   )
    // }

    if (sessionStorage.getItem("etaam_lang") == "ar") this.rtlRef = true;
    else this.rtlRef = false;
    //because there is no  languages
    //-==========================
    this.rtlRef = true;
    //-===========================
    this.customOptions = {
      loop: true,
      margin: 30,
      nav: true,
      navText: [
        "<i class='fa fa-chevron-left'></i>",
        "<i class='fa fa-chevron-right'></i>",
      ],
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      rtl: this.rtlRef,
      dots: false,
      navSpeed: 100,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        740: {
          items: 3,
        },
        940: {
          items: 3,
        },
      },
    };

    // this.supportservice.GetSupportTitle().subscribe((res:any)=>{
    //   this.maincardtitle = res['content'][0].name;
    //   this.maincardinfo = res['content'][0].content;
    //   this.maincardimg = res['content'][0].photo
    //  })

    //  this.associationService.getAllAssociations().subscribe((res:any)=>{
    //    this.associationlist = res.content;
    //  })
  }

  formatVideoURL(videoLink: string) {
    let videoId;
    if (videoLink.indexOf("youtube") > -1) {
      videoId = videoLink.split("watch?v=")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (videoLink.indexOf("vimeo.com") > -1) {
      videoId = videoLink.split("vimeo.com")[1];
      return `https://player.vimeo.com/video${videoId}`;
    } else {
      return videoLink;
    }
  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  goTo(link) {
    switch (link) {
      case "jobs": {
        this.router.navigate(["/recruitment"]);
        break;
      }
      case "associations": {
        this.router.navigate(["/associations"]);
        break;
      }
      case "initiatives": {
        this.router.navigate(["/initiatives"]);
        break;
      }
      default: {
        return;
      }
    }
  }

  openInitiative(ID) {
    this.router.navigate([`/initiatives/${ID}`]);
  }
}
