import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from "ngx-swiper-wrapper";

import { ArticleCardComponent } from "../articles/components/article-card/article-card.component";
import { AuthenticationModule } from "../authentication/authentication.module";
import { ButtonCards2Component } from "./components/button-cards2/button-cards2.component";
import { ButtonCards3Component } from "./components/button-cards3/button-cards3.component";
import { ButtonCards4Component } from "./components/button-cards4/button-cards4.component";
import { CardEventOpportunityComponent } from "./components/card-event-opportunity/card-event-opportunity.component";
import { CardOpportunityComponent } from "./card-opportunity/card-opportunity.component";
import { Cards3Component } from "./components/cards3/cards3.component";
import { Cards4Component } from "./components/cards4/cards4.component";
// import { CarouselModule } from "ngx-owl-carousel-o";
import { Circles3Component } from "./components/circles3/circles3.component";
import { Circles4Component } from "./components/circles4/circles4.component";
import { CommonModule } from "@angular/common";
import { DateCards3Component } from "./components/date-cards3/date-cards3.component";
import { DateFormatterPipe } from "./pipes/date-formatter.pipe";
import { EtaamFooterComponent } from "./components/etaam-footer/etaam-footer.component";
import { EtaamHeaderComponent } from "./components/etaam-header/etaam-header.component";
import { HomeManagementHeadsComponent } from "./components/home-management-heads/home-management-heads.component";
import { HomeSliderComponent } from "./components/home-slider/home-slider.component";
import { HomeWaqfComponent } from "./components/home-waqf/home-waqf.component";
import { ImgTitleCardComponent } from "./components/img-title-card/img-title-card.component";
import { ImgTitleDescriptionCardComponent } from "./components/img-title-description-card/img-title-description-card.component";
import { LatestNewsComponent } from "./components/latest-news/latest-news.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { LoaderService } from "../shared/shared_services/loader.service";
//Custom Modules and components
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgModule } from "@angular/core";
import { NgxPaginationModule } from "ngx-pagination";
import { NumberFormatterPipe } from "./pipes/numberFormatter.pipe";
import { RouterModule } from "@angular/router";
import { ServiceCardComponent } from "./components/service-card/service-card.component";
import { SliderComponent } from "./components/slider/slider.component";
import { SliderTitleDescriptionCardComponent } from "./components/slider-title-description-card/slider-title-description-card.component";
import { StatisticsCardsComponent } from "./components/statistics-cards/statistics-cards.component";
import { TitleDescriptionCardComponent } from "./components/title-description-card/title-description-card.component";
import { TitleDescriptionCards2Component } from "./components/title-description-cards2/title-description-cards2.component";
import { TranslateModule } from "@ngx-translate/core";
import { TextFormatterPipe } from './pipes/text-formatter.pipe';
import { StatementCardComponent } from '../statements/components/statement-card/statement-card.component';
import { MatBadgeModule } from "@angular/material/badge";
import { MatMenuModule } from "@angular/material/menu";
import { SpinnerComponent } from './components/spinner/spinner.component';
// import { TwoCardsComponent } from './components/two-cards/two-cards.component';
import { CarouselModule } from "primeng/carousel";


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};
@NgModule({
  declarations: [
    EtaamFooterComponent,
    EtaamHeaderComponent,
    CardEventOpportunityComponent,
    CardOpportunityComponent,
    DateFormatterPipe,
    ArticleCardComponent,
    LoaderComponent,
    NumberFormatterPipe,
    TextFormatterPipe,

    ButtonCards2Component,
    ButtonCards4Component,
    Cards3Component,
    Cards4Component,
    Circles3Component,
    Circles4Component,
    DateCards3Component,
    ImgTitleCardComponent,
    ImgTitleDescriptionCardComponent,
    SliderComponent,
    SliderTitleDescriptionCardComponent,
    StatisticsCardsComponent,
    TitleDescriptionCardComponent,
    TitleDescriptionCards2Component,
    HomeSliderComponent,
    ButtonCards3Component,
    LatestNewsComponent,
    HomeWaqfComponent,
    HomeManagementHeadsComponent,
    ServiceCardComponent,
    StatementCardComponent,
    SpinnerComponent,
  ],
  exports: [
    EtaamFooterComponent,
    EtaamHeaderComponent,
    CardEventOpportunityComponent,
    CardOpportunityComponent,
    ArticleCardComponent,
    NgxPaginationModule,
    DateFormatterPipe,
    NumberFormatterPipe,
    TextFormatterPipe,
    LoaderComponent,
    MatIconModule,
    MatMenuModule,
    ButtonCards2Component,
    MatDatepickerModule,
    ButtonCards4Component,
    Cards3Component,
    Cards4Component,
    Circles3Component,
    Circles4Component,
    DateCards3Component,
    ImgTitleCardComponent,
    ImgTitleDescriptionCardComponent,
    SliderComponent,
    SliderTitleDescriptionCardComponent,
    StatisticsCardsComponent,
    TitleDescriptionCardComponent,
    TitleDescriptionCards2Component,
    HomeSliderComponent,
    ButtonCards3Component,
    LatestNewsComponent,
    HomeWaqfComponent,
    HomeManagementHeadsComponent,
    ServiceCardComponent,
    StatementCardComponent,
    SpinnerComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
    // CarouselModule,
    TranslateModule.forChild(),
    AuthenticationModule,
    RouterModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatIconModule,
    MatMenuModule,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    // LoaderService,
  ],
})
export class SharedModule { }
