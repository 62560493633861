import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-cards4',
  templateUrl: './button-cards4.component.html',
  styleUrls: ['./button-cards4.component.scss']
})
export class ButtonCards4Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
