<div class="container" style="margin-top: 0px;">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <h3 class="page-title text-left"> المواقع الصديقة </h3>

        </div>
    </div>
    <div class="row">
        <!-- <div class="col-md-4 col-sm-6 col-xs-12" *ngFor = "let item of websiteslist">
            <div style="margin-bottom:20px">
                <div class="card">
                    <div style="text-align: center;">
                        <img src="{{item.image}}" style="width: 100%;">
                    </div>
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-md-9 col-sm-8 xol-xs-8 no-padding">
                                <div class="event-desc text-left">
                                    <h4 class="card-title"> {{item.title}}</h4>
                                    <a class="more" href="{{item.link}}"> الذهاب للموقع <i class="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="col-md-12 col-sm-12 col-xs-12" style="margin-bottom:20px" *ngFor = "let item of websiteslist">

          <div class="friendly-website-div">
            <h4 class="card-title"> {{item.title}}</h4>
            <a class="more" href="{{item.link}}" target="_blank"> الذهاب للموقع <i class="fa fa-arrow-circle-right"></i></a>
          </div>


        </div>

    </div>
</div>
