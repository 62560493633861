import { Component, OnInit } from "@angular/core";
import { ProgramsService } from "../../services/programs.service";

@Component({
  selector: "app-program",
  templateUrl: "./program.component.html",
  styleUrls: ["./program.component.scss"],
})

export class ProgramComponent implements OnInit {

  // programs empty object
  programs_list: any = {};

  constructor(
    private programService: ProgramsService
  ) { }

  ngOnInit(): void {

    // get programs data from api
    this.programService.getPrograms().subscribe((res: any) => {
      // push programs data to object
      this.programs_list = res.content;
    });
  }
}
