import { HttpHeaders } from '@angular/common/http';
import { BaseHttpService } from "src/app/services/base-http.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AboutService {
  header: HttpHeaders = new HttpHeaders();

  constructor(private baseHttpService: BaseHttpService) {}

  getWhoWeAre() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 2856,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  management() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 2887,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  formers() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 2888,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  getDirectors() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 2857,
        pagination: 30,
        page: 1,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  getstratigic() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 2858,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  getGoals() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 2886,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  getCOC() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 2859,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  getCampaign() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 2860,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  getCampaignTools() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 2914,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  getMembers() {
    return this.baseHttpService.post(
      "/api/category_values",
      {
        module_id: 2862,
        category_id: 100,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  // get cities
  getCities() {
    return this.baseHttpService.post(
      "/api/cities",

      {
        lang_id: 1,
        application_id: 188,
      },
      {}
    );
  }

  // get bank data
  getBank() {
    return this.baseHttpService.post(
      "/api/cms",
      {
        module_id: 2905,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }

  // create memberShip
  addMember(body) {
    let setHeaders = this.header.set(
      "access-token",
      localStorage.getItem("login_token")
    );
    return this.baseHttpService.post(
      "/api/memberships/store",
      {
        ...body,
      },
      { headers: setHeaders }
    );
  }

  // call api to get Memberships status
  getMembershipStatus() {
    let setHeaders = this.header.set("email", localStorage.getItem("email"));
    return this.baseHttpService.post(
      "/api/memberships/myMemberships",
      {},
      { headers: setHeaders }
    );
  }

  // call api to get Memberships status search
  getMembershipStatusSearch(search) {
    let baseEventsObject = {
      lang_id: 2,
      title: search,
    };
    let setHeaders = this.header.set("email", localStorage.getItem("email"));
    return this.baseHttpService.post(
      "/api/memberships/myMemberships",
      baseEventsObject,
      { headers: setHeaders }
    );
  }

  // call api to get one membership data
  getMemberShip(id) {
    return this.baseHttpService.post("/api/memberships/edit", {
      id: id,
      lang_id: 2,
    });
  }

  // update memberShip
  updateMember(body, id) {
    let setHeaders = this.header.set(
      "access-token",
      localStorage.getItem("login_token")
    );
    return this.baseHttpService.post(
      "/api/memberships/update",
      {
        ...body,
        id: id,
      },
      { headers: setHeaders }
    );
  }

  // get PdfFile data
  getPdfFile() {
    return this.baseHttpService.post(
      "/api/cms/show",
      {
        id: 2693,
        module_id: 2924,
        lang_id: 2,
        application_id: 188,
      },
      {}
    );
  }
}
