import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PopupComponent } from './shared/popup/popup.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { DonatePopupComponent } from './shared/donate-popup/donate-popup.component';
// multi steps form imports
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// import {TranslateModule} from '@ngx-translate/core';



import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NbThemeModule, NbLayoutModule } from '@nebular/theme';



import { RatingModule } from 'ng-starrating';

// import {StarRatingModule} from 'angular-star-rating';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { GeneralPagesModule } from './general-pages/general-pages.module';
import { SharedModule } from './shared/shared.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpInterceptorService } from './services/http-interceptor.service';

import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';

import { EmbedVideo } from 'ngx-embed-video';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { ApiConstData } from './cms-forms/consts/ApiConstData';
import { GlobalFunctions } from './cms-forms/consts/global-functions';
import { AboutUsComponent } from './about/pages/about-us/about-us.component';
import { DirectorsComponent } from './about/pages/directors/directors.component';
import { StratigicComponent } from './about/pages/stratigic/stratigic.component';
import {GovernanceComponent} from './about/pages/governance/governance.component';
import { CocComponent } from './about/pages/coc/coc.component';
import { Tasksofcommittees1Component } from './about/pages/tasksofcommittees1/tasksofcommittees1.component';
import { PrimaryLawComponent } from './about/pages/primary-law/primary-law.component';
import { PolicyComponent } from './about/pages/policy/policy.component';

import { FinancialComponent } from './about/pages/financial/financial.component';
import { HrComponent } from './about/pages/hr/hr.component';
import { QuestionaireComponent } from './about/pages/questionaire/questionaire.component';
import { MembershipComponent } from './about/pages/membership/membership.component';
import { CampaignComponent } from './about/pages/campaign/campaign.component';
import { AwkafComponent } from './awqaf/pages/awkaf/awkaf.component';
import { MinistriesComponent } from './parteners/pages/ministries/ministries.component';
import { CharitiesComponent } from './parteners/pages/charities/charities.component';
import { UniversityComponent } from './parteners/pages/university/university.component';
import { CommunicationCompaniesComponent } from './parteners/pages/communication-companies/communication-companies.component';
import { BanksComponent } from './parteners/pages/banks/banks.component';
import { NewspaperComponent } from './parteners/pages/newspaper/newspaper.component';
import { OthersComponent } from './parteners/pages/others/others.component';
import { ProgramComponent } from './programs/pages/program/program.component';
import { VolunteerComponent } from './volunteering/pages/volunteer/volunteer.component';

import { MatExpansionModule } from '@angular/material/expansion';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InternetServicesComponent } from './internet-services/internet-services.component';
import { ExportAsModule } from "ngx-export-as";
import { LoadingInterceptor } from './loading.interceptor';

import { ImageViewerModule } from 'ng2-image-viewer';



const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PopupComponent,
    DonatePopupComponent,
    AboutUsComponent,
    DirectorsComponent,
    StratigicComponent,
    CocComponent,
    Tasksofcommittees1Component,

    PrimaryLawComponent,
    PolicyComponent,

    FinancialComponent,
    HrComponent,
    QuestionaireComponent,
    MembershipComponent,
    CampaignComponent,
    AwkafComponent,
    MinistriesComponent,
    CharitiesComponent,
    UniversityComponent,
    CommunicationCompaniesComponent,
    BanksComponent,
    NewspaperComponent,
    OthersComponent,
    ProgramComponent,
    VolunteerComponent,
    InternetServicesComponent,

    // LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    SharedModule,
    SwiperModule,
    CarouselModule,
    ButtonModule,
    ToastModule,
    MatSelectModule,
    // NgMultiSelectDropDownModule.forRoot(),
    RatingModule,
    GeneralPagesModule,
    // NbThemeModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NbThemeModule.forRoot({ name: "default" }),
    NbLayoutModule,
    NbEvaIconsModule,
    ToastrModule.forRoot(),
    NgbModule,
    EmbedVideo.forRoot(),
    AccordionModule.forRoot(),

    MatExpansionModule,
    // MaterialModule,
    NgxExtendedPdfViewerModule,
    PdfViewerModule,
    ExportAsModule,

  ],
  providers: [
    ApiConstData,
    // GlobalFunctions,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    }
    // { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
