import { Component, OnInit } from '@angular/core';
import { PartenersService } from '../../services/parteners.service';


@Component({
  selector: 'app-university',
  templateUrl: './university.component.html',
  styleUrls: ['./university.component.scss']
})
export class UniversityComponent implements OnInit {

  // university list empty object
  universities_List: any[] = [];

  pageSize: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;

  constructor(
    private service: PartenersService
  ) { }

  ngOnInit(): void {
    this.getUniversities();
  }

  // get universities_List data from api
  getUniversities() {
    this.service.getUniversitiesList(this.currentPage).subscribe((res: any) => {
      // push universities_List data to object
      this.universities_List = res.content;
    })
  }

}
