import { Component, OnInit } from "@angular/core";

import { AboutService } from "../../services/about.service";

@Component({
  selector: "app-membership",
  templateUrl: "./membership.component.html",
  styleUrls: ["./membership.component.scss"],
})
export class MembershipComponent implements OnInit {
  memberShip;
  constructor(private AboutService: AboutService) {}

  ngOnInit(): void {
    this.AboutService.getMembers().subscribe((res: any) => {
      this.memberShip = res.content;
    });
  }
}
