import { Component, Input, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';


@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {

  @Input() slides;

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    // hideOnClick: false
  };

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    // observer: true,
    // keyboard: true,
    // mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    effect: 'slide',
    speed: 500,
      // grabCursor: true,
  };

  
  
  constructor() { }

  ngOnInit(): void {

  }

}
