import { Component, OnInit } from '@angular/core';
import {ProductsService} from '../products/services/products.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {



  maincardinfo;
  maincardtitle;
  maincardimg;

  productslist;
  
  constructor( private productservice:ProductsService) { }


  ngOnInit(): void {

  

    this.productservice.getProductsList().subscribe((res:any)=>{
      this.productslist = res.content
    })
    
  }

}
