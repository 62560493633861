import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {TalabatService} from '../services/talabat.service';

@Component({
  selector: 'app-talabat',
  templateUrl: './talabat.component.html',
  styleUrls: ['./talabat.component.scss']
})
export class TalabatComponent implements OnInit {


  itemCount=[];
  items=[];

  talabatlist;

  file_url: any;

  TalabatForm:FormGroup;
  mena7_id;

  uploaded_file = false;
  uploaded_file1 = false;
  filename: any;



  pageSize: number = 10;
  totalArticles: number = 0;
  currentPage: number = 1;



  searchQuery: string;
  searchRes: any[] = [];
  searchSubscription:any;

  searchadvancedQuery:string;

  searchform: FormGroup;

  listArray:any[] = [];

  constructor(private service:TalabatService,
              public fb: FormBuilder,
              private toastr: ToastrService,
    private router: Router) {
              }

  ngOnInit(): void {



    this.Gettalabat();

    this.TalabatForm = this.fb.group({

      final_report: ['', [Validators.required]],
      grant_id : ['', [Validators.required]]
    })

  }

  Gettalabat(){
    this.service.GettalabatList(this.currentPage).subscribe((res:any)=>{
      this.talabatlist = res.content;
      this.currentPage = res.pagination.current_page;
      this.totalArticles = res.pagination.total;
      
    })
  }

  // setFile(file) {
  //   var fileReader = new FileReader();
  //   fileReader.readAsDataURL(file.target.files[0]);
  //   fileReader.onload = () => {
  //    this.talabatForm.controls['report'].patchValue(fileReader.result);
  //   };
  //   fileReader.onerror = (error) => {
  //     
  //   };
  // }

  readFile(event,id) {
    var files = event.target.files[0];
    this.filename = event.target.files[0].name;
    this.uploaded_file1 = true;

    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
    this.mena7_id = id;
  }


  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    // console.log ("The uploaded file :-",this.file_url)

    this.file_url = 'data:file/;base64,' + btoa(binaryString);
    // 

  }

  submit() {
    let submitobj={
      "grant_id":  this.mena7_id ,
      "final_report":this.file_url
    }
     this.service.Submitreport(submitobj).subscribe((res:any)=>{
       console.log ("the obj", submitobj)
       console.log ( "talabat upload file",res)

       if(res.status.code === 200){
         this.uploaded_file = true;
         this.toastr.success('تم بنجاح');
         window.location.reload();
        // this.router.navigate(['/user-area/orders'])

        }
        else if (res.status.code === 401){
          this.toastr.error(res.status.error_details);
        }
        else if (res.status.code === 403){
          let validation_error = res.status.validation_errors;
          
          validation_error.forEach(element => {
            if(element.field == 'module_id') {
              this.toastr.error(element.message);
            }

          else if(element.field == 'grant_id') {
              this.toastr.error(element.message);
            }

            else if(element.field == 'final_report') {
              this.toastr.error(element.message);
            }

          })
        }

     },error =>{
      
      if (error.error.status.code === 403) {
        let validation_error = error.error.status.validation_errors;
        
        validation_error.forEach(element => {
          if(element.field == 'module_id') {
            this.toastr.error(element.message);
          }

        else if(element.field == 'grant_id') {
            this.toastr.error(element.message);
          }

          else if(element.field == 'final_report') {
            this.toastr.error(element.message);
          }

        })

      }
     }
     )
  }

  navigategrants(grantid){
    this.router.navigate(['/user-area/grants-edit'], {
      queryParams: {
        id: grantid
      }
    })

  }

  settings = {
    columns: {
      id: {
        title: 'رقم الطلب'
      },
      name: {
        title: 'اسم البرنامج أو المشروع'
      },
      status: {
        title: 'حالة الطلب'
      },
      comment: {
        title: 'التعليق علي الطلب'
      },
      date: {
        title: 'تاريخ تسجيل الطلب'
      },
      edit: {
        title: 'التعديل'
      },
      file: {
        title: 'رفع التقرير'
      },
    }
  };


  data = [
    {
      id: 1,
      name: "test Graham",
      status: "Bret",
      comment: "test@april.com",
      date:"",
      edit:"",
      file:""
    },
    {
      id: 2,
      name: "test Howell",
      status: "test",
      comment: "test@gmail.tv",
      date:"",
      edit:"",
      file:""
    },
    {
      id: 11,
      name: "testDuBuque",
      status: "test.Stanton",
      comment: "test@test.biz",
      date:"",
      edit:"",
      file:""
    }
  ]

  pageChanged(page) {
    this.currentPage = page;
    this.Gettalabat();

  }

  search(){

    if(this.searchQuery) {
      if(this.searchSubscription) {
        this.searchSubscription.unsubscribe();
      }
      this.service.Searchtalabatlist(this.searchQuery).subscribe((res:any) => {
        this.talabatlist = res.content;
        
      });

    }
     else {
      this.searchRes = [];

      return;
    }



  }

  ResetSearch(){
    this.service.GettalabatList(this.currentPage).subscribe((res:any) => {
      this.talabatlist = res.content;
      this.currentPage = res.pagination.current_page;
      this.totalArticles = res.pagination.total;
      // 
    });
  }

  DeleteListID(event,id) {

    if (event.target.checked) {
      this.listArray.push({id});
    }
    else {
      let index = this.listArray.findIndex(element => element == id);
      this.listArray.splice(index,1);
    }

    // 
  }

  DeleteTableRecords(){
    // 

    this.service.DeleteFromTable(this.listArray).subscribe((res:any)=>{
      // console.log ("Delete API Response :-",res)
      this.toastr.success('تم بنجاح');
      window.location.reload()
    })
  }

}
