import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-by-id',
  templateUrl: './order-by-id.component.html',
  styleUrls: ['./order-by-id.component.scss']
})
export class OrderByIdComponent implements OnInit {
  grant;
  grantPaths: any[];
  grantDomain: any[];
  grantBanks: any[];
  printableDoc: any;
  constructor( private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    
  }


  getSelectedValueName(controlName, value) {
    switch (controlName) {
      case 'path': {
        if (this.grantPaths.length) {
          let path = this.grantPaths.find(element => element.id == value);
          if (path) {
            return path.name;
          } else {
            return '';
          }
        }
      }
      case 'domain': {
        if (this.grantDomain.length) {
          let domain = this.grantDomain.find(element => element.id == value);
          if (domain) {
            return domain.name
          } else {
            return '';
          }
        }
      }
      case 'bank': {
        if (this.grantBanks.length) {
          let bank = this.grantBanks.find(element => element.id == value);
          if (bank) {
            return bank.name
          } else {
            return ''
          }
        }
      }
      default: {
        return '';
      }
    }
  }

  printFile(grantID) {
    let w = window.open(`https://appox.xyz/system/pentalibrary/public/api/grants/pdf/${grantID}`, '_blank');
  }

  goToOrders() {
    this.router.navigate(['../user-area']);
  }

}
