import { BaseHttpService } from "src/app/services/base-http.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PartenersService {
  constructor(
    private baseHttpService: BaseHttpService
  ) { }

  // call api to get ministries data
  getMinistriesList() {
    let baseObject = {
      module_id: 2866,
      application_id: 188,
      lang_id : 2
    };
    return this.baseHttpService.post("/api/cms", baseObject);
  }

  // call api to get banks list
  getBanksList(currentPage = 1) {
    let baseObject = {
      module_id: 2870,
      application_id: 188,
      lang_id : 2
    };
    return this.baseHttpService.post("/api/cms", baseObject, {});
  }

  // call api to get charities list
  getCharitiesList(currentPage = 1) {
    let baseObject = {
      module_id: 2867,
      application_id: 188,
      lang_id : 2
    };
    return this.baseHttpService.post("/api/cms", baseObject, {});
  }

  // call api to get communication companies list
  getCommunicationCompaniesList(currentPage = 1) {
    let baseObject = {
      module_id: 2869,
      application_id: 188,
      lang_id : 2
    };
    return this.baseHttpService.post("/api/cms", baseObject, {});
  }

  // call api to get newspaper list
  getNewspaperList(currentPage = 1) {
    let baseObject = {
      module_id: 2871,
      application_id: 188,
      lang_id : 2

    };
    return this.baseHttpService.post("/api/cms", baseObject, {});
  }

  // call api to get other list
  getOtherList(currentPage = 1) {
    let baseObject = {
      module_id: 2873,
      application_id: 188,
      lang_id : 2
    };
    return this.baseHttpService.post("/api/cms", baseObject, {});
  }

  // call api to get universities list
  getUniversitiesList(currentPage = 1) {
    let baseObject = {
      module_id: 2868,
      application_id: 188,
      lang_id : 2

    };
    return this.baseHttpService.post("/api/cms", baseObject, {});
  }
}
