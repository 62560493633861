<div class="full-width">
    <div class="row page-title">

      <div class="col-md-6 col-sm-6 col-xs-12">
        <h3>
            لائحة الموارد البشرية   
        </h3>
      </div>

      <div class="col-md-6 col-sm-6 col-xs-12">
        <nav aria-label="breadcrumb" class="titles">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
              <li class="breadcrumb-item" style="padding-left: 0px;"><a routerLink="about/HR" style="padding-right: 10px;"> لائحة الموارد البشرية  </a></li>
            </ol>
        </nav>
      </div>

    </div>
</div>



<div class="row">
  
  <div class="container">

    <div class="row">

      <div class="col-md-6 col-sm-12 col-xs-12" style="padding: 25px;">
        

          <ngx-extended-pdf-viewer
            src="assets/home.pdf"
            [useBrowserLocale]="true"
            [textLayer]="true"
            [showHandToolButton]="true"
            [showPresentationModeButton]="true"
            [showDownloadButton]="false"
            style="height: 500px !important"
          ></ngx-extended-pdf-viewer>

      </div> 
      <div class="col-md-6 col-sm-12 col-xs-12 download-padding" style="text-align:center;">

        <h6 class="download-title">يمكنك تحميل ملف لائحة الموارد البشرية</h6>

        <button class="download-btn">تحميل الملف الان</button>

      </div>

    </div>

  </div>
</div>

