<div>
  <div class="bar">
    <h2>الخدمات الالكترونية</h2>
  </div>
  <div class="container-fluid">
    <div class="row">
      <!-- search -->
      <!-- <div class="search_wrapper">
      <input class="form-control input-in" type="text" name="search" placeholder="البحث" [(ngModel)]="searchQuery"
        [ngModelOptions]="{ standalone: true }" />
      <div class="btn_wrapper">
        <button>
          <label> بحث </label>
          <mat-icon>search</mat-icon>
        </button>
        <button (click)="showAdvancedSearch = true">
          <label>بحث متقدم</label>
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div> -->
      <!-- <div class="advanced_search_wrapper" *ngIf="showAdvancedSearch">
      <div class="btn_wrapper inactive">
        <label>حالة الطلب</label>
        <button [class.active]="selectedType == 'a'" (click)="selectedType = 'a'">
          مقبول
        </button>
        <button [class.active]="selectedType == 'r'" (click)="selectedType = 'r'">
          مرفوض
        </button>
        <button [class.active]="selectedType == 'p'" (click)="selectedType = 'p'">
          قيد التنفيذ
        </button>
      </div>
      <div class="date_wrapper">
        <div class="date">
          <label> التاريخ التقديم من </label>
          <mat-form-field appearance="outline" floatLabel="never">
            <mat-label>MM/DD/YYYY</mat-label>
            <input matInput [matDatepicker]="datepicker" disabled />
            <mat-datepicker-toggle matPrefix [for]="datepicker">
              <img src="../../assets/images/calendar.svg" matDatepickerToggleIcon />
            </mat-datepicker-toggle>
            <mat-datepicker #datepicker disabled="false"> </mat-datepicker>
          </mat-form-field>
        </div>
        <div class="date">
          <label> التاريخ التقديم الى </label>
          <mat-form-field appearance="outline" floatLabel="never">
            <mat-label>MM/DD/YYYY</mat-label>
            <input matInput [matDatepicker]="datepickerEnd" disabled />
            <mat-datepicker-toggle matPrefix [for]="datepickerEnd">
              <img src="../../assets/images/calendar.svg" matDatepickerToggleIcon />
            </mat-datepicker-toggle>
            <mat-datepicker #datepickerEnd disabled="false"> </mat-datepicker>
          </mat-form-field>
        </div>
        <div class="btn_wrapper search_btn">
          <button>
            <label> عرض النتائج</label>
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </div> -->
      <div class="d-flex mt-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <ul
          ngbNav
          #nav="ngbNav"
          [(activeId)]="active"
          class="nav-pills"
          orientation="vertical"
        >
          <li ngbNavItem="programs">
            <a ngbNavLink>البرامج</a>
            <ng-template ngbNavContent>
              <!-- search -->
              <div class="search_wrapper row">
                <div class="col-md-10 px-0 mb-2">
                  <input
                    class="form-control input-in"
                    type="text"
                    name="search"
                    placeholder="البحث"
                    [(ngModel)]="programSearchQuery"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>

                <div class="col-md-2 mb-2 btn_wrapper">
                  <button (click)="programSearch()">
                    <label> بحث </label>
                    <mat-icon>search</mat-icon>
                  </button>
                  <button (click)="ResetSearch()">
                    <mat-icon>refresh</mat-icon>
                  </button>
                </div>
              </div>
              <app-service-card
                *ngIf="programs_list.length > 0"
                [services_list]="programs_list"
                [type]="'program'"
              ></app-service-card>
              <div
                *ngIf="programs_list.length < 1"
                class="text-center no-content-card"
              >
                <img
                  class="image"
                  src="/assets/images/about1.PNG"
                  alt="no-membership"
                />
                <h3 class="desc">لا توجد تقديم للبرامج في الوقت الحالي</h3>
                <button class="btn" routerLink="/programs">
                  تقدم الى البرنامج
                </button>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="membership">
            <a ngbNavLink>العضويات</a>
            <ng-template ngbNavContent>
              <!-- search -->
              <div class="search_wrapper row">
                <div class="col-md-10 px-0 mb-2">
                  <input
                    class="form-control input-in"
                    type="text"
                    name="search"
                    placeholder="البحث"
                    [(ngModel)]="membershipsSearchQuery"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-md-2 mb-2 btn_wrapper">
                  <button (click)="membershipSearch()">
                    <label> بحث </label>
                    <mat-icon>search</mat-icon>
                  </button>
                  <button (click)="ResetSearch()">
                    <mat-icon>refresh</mat-icon>
                  </button>
                </div>
              </div>

              <app-service-card
                *ngIf="membership_list.length > 0"
                [services_list]="membership_list"
                [type]="'membership'"
              ></app-service-card>
              <div
                *ngIf="membership_list.length < 1"
                class="text-center no-content-card"
              >
                <img
                  class="image"
                  src="/assets/images/member-card (2).png"
                  alt="no-membership"
                />
                <h3 class="desc">لا توجد تقديم للعضويات في الوقت الحالي</h3>
                <button class="btn" routerLink="/about/members">
                  اطلب العضوية الان
                </button>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem="volunteer">
            <a ngbNavLink>التطوع</a>
            <ng-template ngbNavContent>
              <!-- search -->
              <div class="search_wrapper row">
                <div class="col-md-10 px-0 mb-2">
                  <input
                    class="form-control input-in"
                    type="text"
                    name="search"
                    placeholder="البحث"
                    [(ngModel)]="volunteerSearchQuery"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-md-2 mb-2 btn_wrapper">
                  <button (click)="volunteerSearch()">
                    <label> بحث </label>
                    <mat-icon>search</mat-icon>
                  </button>
                  <button (click)="ResetSearch()">
                    <mat-icon>refresh</mat-icon>
                  </button>
                </div>
              </div>

              <app-service-card
                *ngIf="volunteer_list.length > 0"
                [services_list]="volunteer_list"
                [type]="'volunteer'"
              ></app-service-card>
              <div
                *ngIf="volunteer_list.length < 1"
                class="text-center no-content-card"
              >
                <img
                  class="image"
                  src="/assets/images/volunteer.PNG"
                  alt="no-membership"
                />
                <h3 class="desc">لا توجد تقديم للتطوعات في الوقت الحالي</h3>
                <button class="btn" routerLink="/volunteers">تطوع معنا</button>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="ms-4"></div>
      </div>
    </div>
  </div>
</div>
