import { AppInfoService } from './../../shared_services/app-info.service';
import * as $ from "jquery";

import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";

import { ArticlesService } from "../../../articles/services/articles.service";
import { MenuService } from "../../../services/General/menu.service";
import { NotificationsService } from "../../../shared/shared_services/notifications.service";
import { PusherService } from "src/app/services/pusher/pusher.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-etaam-header",
  templateUrl: "./etaam-header.component.html",
  styleUrls: ["./etaam-header.component.scss"],
})
export class EtaamHeaderComponent implements OnInit, OnDestroy {

  governance_list: any = {};

  showLevel2 = false;
  showLevel3 = false;
  showLevel1 = false;
  myprofile = false;
  mylogin = false;

  token;
  notificationsList: any[] = [];
  notification_count: number = 0;

  searchQuery: string;
  searchRes: any[] = [];
  searchSubscription: any;

  A5barsearchRes: any[] = [];
  Fa3eleyatsearchRes: any[] = [];
  MobadaratsearchRes: any[] = [];
  check_readnotification: any;

  MenuItems;
  news;

  APP_ID;

  username: string = "";
  isLogin: boolean = false;

  pageSize: number = 5;

  totalNotifications: number = 0;

  currentPage: number = 1;

  isLength: boolean = false;

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private notifi: NotificationsService,
    private pusherService: PusherService,

    private a5barservice: ArticlesService,

    private servvise: MenuService,
    private cdr: ChangeDetectorRef,
    private appInfoService: AppInfoService,
  ) {}

  ngOnInit(): void {
    this.servvise.getGovernance().subscribe((res: any) => {
      if (res.content) {
        this.governance_list = res.content;
      
      }
    });

    this.appInfoService.currentUser.subscribe(() => {
      this.username = this.appInfoService.currentUser.getValue();
      this.token = this.appInfoService.currentToken.getValue();
    })

    this.appInfoService.isNotify.subscribe(() => {
      let call = this.appInfoService.isNotify.getValue();
      if (call) {
        this.GetNotifications();
      }
    })

    // this.username = localStorage.getItem("username");
    this.APP_ID = localStorage.getItem("APP_ID");
    this.pusherService.channel.bind("new-notification", (data) => {
      this.toastr.success(data.content.notification_text);
      // this.notificationsList.push(data);
    });

    if (this.username) {
      this.isLogin = true;
      this.GetNotifications();
    }

    if (this.APP_ID) {
      this.servvise.GetMenuItems().subscribe((res: any) => {
        this.MenuItems = res.Items;
        this.news = res.Items[13].flow[0].layout_id;
        localStorage.setItem("form", JSON.stringify(res.Items[12].flow[0]));
        // 
        // console.log(
        //   "form parameters in localstorage ============>",
        //   JSON.parse(localStorage.getItem("form"))
        // );
      });
    }

  }

  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }

  closesearch() {
    document.getElementById("togglesearch").style.display = "none";
  }

  closeProfile(val) {
    this.myprofile = val;
  }
  closeLogin(val) {
    this.mylogin = val;
    $("body").off();
  }
  viewProfile() {
    if (this.myprofile == false) {
      this.myprofile = true;
    }
  }
  viewLogin() {
    if (this.mylogin == false) {
      this.mylogin = true;
      setTimeout(() => {
        $("body").on("click", (event) => {
          if ($(event.target).closest(".popup.hover_bkgr_fricc").length == 0) {
            this.mylogin = false;
            $("body").off();
          }
        });
      }, 200);
    }
  }

  changeLang(lang) {
    this.translateService.setDefaultLang(lang);
    sessionStorage.setItem("etaam_lang", lang);
  }

  Logout() {
    localStorage.removeItem("login_token");
    localStorage.removeItem("login_assoc");
    localStorage.removeItem("APP_ID");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
    localStorage.removeItem("slides");
    this.appInfoService.currentUser.next('');
    this.appInfoService.currentToken.next('');
    // localStorage.removeItem('Application_ID');
    this.isLogin = false;
    this.toastr.success("لقد تم تسجيل الخروج بنجاح");
    window.location.replace("/home");
  }

  private GetNotifications() {
    this.notifi
      .getAllNotifications(this.currentPage, this.pageSize)
      .subscribe((res: any) => {
        this.notificationsList = res.content.data;
        this.notification_count = res.content.count;
        this.notificationsList.forEach((element) => {
          if (element.displayed_at == null) {
            this.addReadClass(element.id);
          }
          if (res.pagination.total == res.pagination.to) {
            this.isLength = true;
          } else {
            this.isLength = false;
          }
        });
      });
  }

  seeMore() {
    this.pageSize += 5;
    this.GetNotifications();
  }

  addReadClass(id) {
    document.getElementById(`notify${id}`).classList.toggle("read");
  }

  readselected(item) {
    if (item.displayed_at == null) {
      this.notifi.updateReadNotification(item.id).subscribe((res: any) => {
        this.notification_count -= 1;
      });
    }
    this.cdr.detectChanges();
    this.router.navigateByUrl("/staticPages");
    this.GetNotifications();
  }

  search() {
    if (this.searchQuery) {
      this.status = false;
      this.router.navigate(["search"], {
        queryParams: {
          query: this.searchQuery,
        },
      });
    } else {
      return;
    }
  }

  FixTheStyle() {
    $("body").removeClass("nb-theme-default");
  }

  NavigatetoModule(item) {
    $("body").removeClass(" nb-theme-default");
    localStorage.setItem("apiparameter", JSON.stringify(item.flow[0]));
    localStorage.setItem("ModuleID", JSON.stringify(item.name));
    localStorage.setItem("ModuleNo", JSON.stringify(item.module_id));
  
    // this.router.navigate(['/cmsforms'], { queryParams: { formparams:JSON.parse( item.flow[0]) } });

    if (item.flow[0].layout_id == 1) {
      this.router.navigate(["/cms/1"], {
        queryParams: { module: JSON.parse(localStorage.getItem("ModuleID")) },
      });
    } else if (item.flow[0].layout_id == 2) {
      this.router.navigate(["/cms/2"], {
        queryParams: { module: JSON.parse(localStorage.getItem("ModuleID")) },
      });
    } else if (item.flow[0].layout_id == 3) {
      // Go to General Pages Module
      this.router.navigate(["/cmspages"], {
        queryParams: { module: JSON.parse(localStorage.getItem("ModuleID")) },
      });
    }

    // else if (item.flow[0].name == "الاخبار" && item.flow[0].layout_id == 2){
    //   // Go to Forms  Module
    //   this.router.navigate(['/cmsforms'], { queryParams: { formparams: JSON.parse(item.flow[0].flow[1]) } });

    // }
  }

  navigatetoforms() {
    $("body").removeClass(" nb-theme-default");
    this.router.navigate(["/cmsforms"], {
      queryParams: { formparams: localStorage.getItem("form") },
    });
  }

  ngOnDestroy(): void {
    this.appInfoService.currentUser.unsubscribe();
    this.isLogin = false
  }
}
