<div class="row">
    <div class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;margin-bottom: 30px;">
        <img class="circle-img" src="assets/images/circle1.jpg">
        <h6 class="names" style="text-align: center;">صاحب السمو الملكي الأمير سلطان بن فهد بن سلمان بن عبدالعزيز</h6>
        <small class="small-text" style="float: initial;">  عضو</small>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12" style="text-align: center;margin-bottom: 30px;">
        <img class="circle-img" src="assets/images/circle2.jpg">
        <h6 class="names" style="text-align: center;"> صاحب السمو الأمير الدكتور عبدالعزيز بن محمد بن عياف آل مقرن</h6>
        <small class="small-text" style="float: initial;">  عضو</small>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12" style="text-align: center; margin-bottom: 30px;">
        <img class="circle-img" src="assets/images/circle3.jpg">
        <h6 class="names" style="text-align: center;">صاحب السمو الأمير سلطان بن محمد بن سعود الكبير</h6>
        <small class="small-text" style="float: initial;">  عضو</small>
    </div>
</div>