<div class="full-width">
  <div class="row page-title">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <h3>تواصل معنا</h3>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12">
      <nav aria-label="breadcrumb" class="titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
          <li class="breadcrumb-item" style="padding-left: 0px">
            <a routerLink="../contact" style="padding-right: 10px"
              >تواصل معنا</a
            >
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>
<div class="contact" style="margin-top: 30px; margin-bottom: 60px">
  <div class="container">
    <div class="row">
      <!-- contact us details -->
      <div class="col-md-6 col-sm-12 col-xs-12 pt-5">
        <h4 class="main-title uppercase bold" style="text-align: right">
          يسعدنا اهتمامك و تواصلك بنا
        </h4>
        <h6 class="subtitle">
          اذا كنت تحتاج إلى مساعدة أو إرسال شكوى لا تتردد
        </h6>
        <div class="card" style="background: rgb(254 254 254)">
          <div class="row">
            <div
              class="col-md-12 col-sm-12 col-xs-12 pull-left text-left"
              id="left"
            >
              <h6 class="main-title uppercase bold text-dark">
                {{ "GENERAL_PAGES.CONTACT.MAIN_BRANCH" | translate }}
              </h6>
              <div class="branch">
                <div class="contact-info">
                  <div class="row">
                    <div class="col-md-12">
                      <p>
                        <i class="fa fa-map-marker gold"></i>
                        <b>العنوان:</b> &nbsp;<span>{{
                          contactData?.address
                        }}</span>
                      </p>
                    </div>
                    <div class="col-md-8">
                      <p class="numbers-p">
                        <i class="fa fa-phone gold"></i>
                        <b>رقم الهاتف :</b> &nbsp;
                        <span class="Numbers-font" >{{ contactData?.mobile}}</span> 
                        <span class="vertical_separator"></span>
                        <span class="Numbers-font" >  {{ contactData?.landline}}</span>
                      </p>
                      <p>
                        <i class="fa fa-envelope gold"></i>
                        <b>البريد الإلكتروني:</b> &nbsp;<span>{{
                          contactData?.email
                        }}</span>
                      </p>
                      <p>
                        <i class="fa fa-fax gold"></i> <b>فاكس:</b> &nbsp;<span
                          class="Numbers-font"
                          >{{ contactData?.fax }}</span
                        >
                      </p>
                    </div>
                    <div class="col-md-4">
                      <img
                        src="/assets/images/Support.png"
                        width="100%"
                        height="100%"
                        alt="support"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- complaints and contact us forms -->
      <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="tabs-container">
          <input
            style="display: none"
            type="radio"
            id="tab2"
            name="tab"
            checked
            (click)="tab(1)"
          />
          <label class="tab-label text-secondary font-weight-bold" for="tab2"
            >اتصل بنا</label
          >
          <input
            style="display: none"
            type="radio"
            id="tab1"
            name="tab"
            (click)="tab(2)"
          />
          <label class="tab-label text-secondary font-weight-bold" for="tab1"
            >للشكاوي</label
          >

          <div class="content-container clearfix">
            <!-- contact us form -->
            <div class="content" id="c2">
              <div class="container">
                <form
                  class="custom full-width form-padding"
                  [formGroup]="contactForm"
                  (ngSubmit)="submit(contactForm.value)"
                  enctype="multipart/form-data"
                >
                  <div class="form-group">
                    <input
                      type="text"
                      formControlName="name"
                      class="form-control"
                      placeholder="الاسم"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      type="email"
                      formControlName="email"
                      class="form-control"
                      placeholder="{{
                        'GENERAL_PAGES.CONTACT.EMAIL' | translate
                      }}"
                    />
                    <span
                      *ngIf="
                        contactForm.get('email').touched &&
                        contactForm.get('email').invalid
                      "
                    >
                      <small
                        *ngIf="contactForm.get('email').errors.required"
                        class="form-text required"
                      >
                        برجاء ادخال البريد الالكتروني
                      </small>
                      <small
                        *ngIf="contactForm.get('email').errors.pattern"
                        class="form-text required"
                      >
                        برجاء ادخال بريد الكتروني صحيح
                      </small>
                    </span>
                  </div>

                  <div class="form-group">
                    <textarea
                      class="form-control"
                      formControlName="message"
                      placeholder="{{
                        'GENERAL_PAGES.CONTACT.MESSAGE' | translate
                      }}"
                    ></textarea>
                  </div>

                  <button
                    type="button"
                    class="btn-custom"
                    type="submit"
                    style="border-radius: 5px !important"
                  >
                    {{ "GENERAL_PAGES.CONTACT.SEND" | translate }}
                  </button>
                </form>
              </div>
            </div>
            <!-- complaints form -->
            <div class="content" id="c1">
              <div class="container">
                <form
                  class="custom full-width form-padding"
                  [formGroup]="complaintsForm"
                  (ngSubmit)="submit(complaintsForm.value)"
                  enctype="multipart/form-data"
                >
                  <div class="form-group">
                    <input
                      type="text"
                      formControlName="name"
                      class="form-control"
                      placeholder="{{
                        'GENERAL_PAGES.CONTACT.NAME' | translate
                      }}"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      type="email"
                      formControlName="email"
                      class="form-control"
                      placeholder="{{
                        'GENERAL_PAGES.CONTACT.EMAIL' | translate
                      }}"
                    />
                    <span
                      *ngIf="
                        complaintsForm.get('email').touched &&
                        complaintsForm.get('email').invalid
                      "
                    >
                      <small
                        *ngIf="complaintsForm.get('email').errors.required"
                        class="form-text required"
                      >
                        برجاء ادخال البريد الالكتروني
                      </small>
                      <small
                        *ngIf="complaintsForm.get('email').errors.pattern"
                        class="form-text required"
                      >
                        برجاء ادخال بريد الكتروني صحيح
                      </small>
                    </span>
                  </div>

                  <div class="form-group">
                    <input
                      type="text"
                      formControlName="address"
                      class="form-control"
                      placeholder="العنوان"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      type="tel"
                      formControlName="mobile"
                      class="form-control Numbers-font"
                      placeholder="{{
                        'GENERAL_PAGES.CONTACT.PHONE' | translate
                      }}"
                    />

                    <span
                      *ngIf="
                        complaintsForm.get('mobile').touched &&
                        complaintsForm.get('mobile').invalid
                      "
                    >
                      <small
                        *ngIf="complaintsForm.get('mobile').errors.required"
                        class="form-text required"
                      >
                        برجاء ادخال رقم هاتف
                      </small>
                      <small
                        *ngIf="complaintsForm.get('mobile').errors.pattern"
                        class="form-text required"
                      >
                        برجاء ادخال رقم هاتف صحيح
                      </small>
                    </span>
                  </div>

                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 col-xs-12 pull-left">
                        <div class="upload-btn-wrapper form-control">
                          <button class="btnr" title="اختر ملف" type="button">
                            اختر ملف
                          </button>
                          <span *ngIf="uploaded_file == false"
                            >لم تقم باختيار ملف</span
                          >
                          <span>{{ fileName }}</span>
                          <input
                            type="file"
                            (change)="readImage($event)"
                            class="form-control"
                            formControlName="document"
                            accept="application/msword,application/pdf,.doc, .docx , .pdf , .png , .jpg , .jpeg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <textarea
                      class="form-control"
                      formControlName="message"
                      placeholder="{{
                        'GENERAL_PAGES.CONTACT.MESSAGE' | translate
                      }}"
                    ></textarea>
                  </div>

                  <button
                    type="button"
                    class="btn-custom"
                    type="submit"
                    style="border-radius: 7px !important"
                  >
                    {{ "GENERAL_PAGES.CONTACT.SEND" | translate }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- location QR code -->
      <div class="col-md-6 col-sm-12 col-xs-12">
        <h6 class="subtitle">امسح الكود للوصول الي العنوان</h6>
        <div class="card p-3 text-center">
          <img
            src="/assets/images/address-location.png"
            height="300"
            width="300"
            alt="scan-location"
          />
        </div>
      </div>
    </div>
  </div>
</div>
