import { ProgramsService } from "./../programs/services/programs.service";
import { AboutService } from "./../about/services/about.service";
import { VolunteeringService } from "./../volunteering/services/volunteering.service";
import { Component, OnInit } from "@angular/core";

import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-internet-services",
  templateUrl: "./internet-services.component.html",
  styleUrls: ["./internet-services.component.scss"],
})
export class InternetServicesComponent implements OnInit {
  active = "programs";

  membershipsSearchQuery;

  volunteerSearchQuery;

  programSearchQuery;

  // showAdvancedSearch = false;

  selectedType = "a";

  searchSubscription: any;

  searchRes: any[] = [];

  // isHidden;

  membership_list: any = [];

  volunteer_list: any = [];

  programs_list: any = [];

  constructor(
    private volunteeringService: VolunteeringService,
    private aboutService: AboutService,
    private programsService: ProgramsService
  ) { }

  ngOnInit(): void {
    this.getVolunteerStatus();
    this.getMembershipStatus();
    this.getProgramStatus();
  }

  // get volunteer status
  getVolunteerStatus() {
    this.volunteeringService.getVolunteerStatus().subscribe((res: any) => {
      if (res.content) {
        this.volunteer_list = res.content;

        this.volunteer_list.forEach(item => {
          item.icon = "../../assets/images/Group 64812.svg";
          if (item.membership_status.id == 1) {
            item.type = "p";
          } else if (item.membership_status.id == 3) {
            item.type = "r";
          } else if (item.membership_status.id == 4) {
            item.type = "a";
          }
        });
      }
    });
  }

  // get membership status
  getMembershipStatus() {
    this.aboutService.getMembershipStatus().subscribe((res: any) => {
      if (res.content) {
        this.membership_list = res.content;
        this.membership_list.forEach((item: any) => {
          item.icon = "../../assets/images/join-us.svg";
          if (item.membership_status.id == 1) {
            item.type = "p";
          } else if (item.membership_status.id == 3) {
            item.type = "r";
          } else if (item.membership_status.id == 4) {
            item.type = "a";
          }
        });
      }
    });
  }

  // get program status
  getProgramStatus() {
    this.programsService.getProgramsStatus().subscribe((res: any) => {
      if (res.content) {
        this.programs_list = res.content;

        this.programs_list.forEach(item => {
          item.icon = "/assets/images/notebook.svg";
          if (item.membership_status.id == 1) {
            item.type = "p";
          } else if (item.membership_status.id == 3) {
            item.type = "r";
          } else if (item.membership_status.id == 4) {
            item.type = "a";
          }
        });
      }
    });
  }

  // members search
  membershipSearch() {
    // to make advanced search form hidden
    // this.isHidden = false;

    // search by title
    if (this.membershipsSearchQuery) {
      if (this.searchSubscription) {
        this.searchSubscription.unsubscribe();
      }

      // get members by title search
      this.aboutService
        .getMembershipStatusSearch(this.membershipsSearchQuery)
        .subscribe((res: any) => {
          if (res.content) {
            this.membership_list = res.content;
            for (let i = 0; i < this.membership_list.length; i++) {
              this.membership_list[i].icon = "../../assets/images/health5.jpg";
              if (this.membership_list[i].membership_status.id == 1) {
                this.membership_list[i].type = "p";
              } else if (this.membership_list[i].membership_status.id == 3) {
                this.membership_list[i].type = "r";
              } else if (this.membership_list[i].membership_status.id == 4) {
                this.membership_list[i].type = "a";
              }
            }
          }
        });
    } else {
      this.searchRes = [];
      return;
    }
  }

  // volunteer search
  volunteerSearch() {
    // to make advanced search form hidden
    // this.isHidden = false;

    // search by title
    if (this.volunteerSearchQuery) {
      if (this.searchSubscription) {
        this.searchSubscription.unsubscribe();
      }

      // get volunteer by title search
      this.volunteeringService
        .getVolunteerStatusSearch(this.volunteerSearchQuery)
        .subscribe((res: any) => {
          if (res.content) {
            this.volunteer_list = res.content;
            for (let i = 0; i < this.volunteer_list.length; i++) {
              this.volunteer_list[i].icon =
                "../../assets/images/Group 64812.svg";
              if (this.volunteer_list[i].membership_status.id == 1) {
                this.volunteer_list[i].type = "p";
              } else if (this.volunteer_list[i].membership_status.id == 3) {
                this.volunteer_list[i].type = "r";
              } else if (this.volunteer_list[i].membership_status.id == 4) {
                this.volunteer_list[i].type = "a";
              }
            }
          }
        });
    } else {
      this.searchRes = [];
      return;
    }
  }

  // program search
  programSearch() {
    // to make advanced search form hidden
    // this.isHidden = false;

    // search by title
    if (this.programSearchQuery) {
      if (this.searchSubscription) {
        this.searchSubscription.unsubscribe();
      }

      // get program by title search
      this.programsService
        .getProgramsStatusSearch(this.programSearchQuery)
        .subscribe((res: any) => {
          if (res.content) {
            this.programs_list = res.content;
            for (let i = 0; i < this.programs_list.length; i++) {
              this.programs_list[i].icon =
                "../../assets/images/Group 64812.svg";
              if (this.programs_list[i].membership_status.id == 1) {
                this.programs_list[i].type = "p";
              } else if (this.programs_list[i].membership_status.id == 3) {
                this.programs_list[i].type = "r";
              } else if (this.programs_list[i].membership_status.id == 4) {
                this.programs_list[i].type = "a";
              }
            }
          }
        });
    } else {
      this.searchRes = [];
      return;
    }
  }

  // to reset search
  ResetSearch() {
    this.programSearchQuery = "";
    this.membershipsSearchQuery = "";
    this.volunteerSearchQuery = "";
    // this.isHidden = true;

    this.getMembershipStatus();
    this.getVolunteerStatus();
    this.getProgramStatus();
  }
}
