<div class="row">
  <div class="col-md-6 col-sm-6 col-xs-6">
    <h3 class="main-title">اخر الأخبار</h3>
  </div>
  <div class="col-md-6 col-sm-6 col-xs-6">
    <a class="all-btn" routerLink="/articles">الكل</a>
  </div>
</div>

<div class="row">
  <div class="col-md-6 col-sm-6 col-xs-12">
    <div class="card">
      <div
        class="card-img-top"
        [style]="{ 'background-image': 'url(' + news.media[0].image + ')' }"
      ></div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="event-desc text-left">
              <h4 class="card-title">
                {{ news.title }}
              </h4>
              <p>
                {{ news.content | textFormatter }}
              </p>
              <a class="more" routerLink="/articles/{{ news.id }}">
                إقرأ المزيد
                <i class="fa fa-arrow-circle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-6 col-xs-12">
    <div class="row">
      <div *ngFor="let news of news_list" class="col-md-6 col-sm-6 col-xs-12">
        <div class="card card2">
          <div
            class="card-img-top"
            [style]="{
              'background-image': 'url(' + news.media[0]?.image + ')'
            }"
          ></div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="event-desc text-left">
                  <h4 class="card-title">
                    {{ news.title }}
                  </h4>
                  <a class="more" routerLink="/articles/{{ news.id }}">
                    إقرأ المزيد
                    <i class="fa fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card2">
              <div class="card-img-top" style="background-image: url(assets/images/news1.jpeg);"></div>
              <div class="card-body ">
                <div class="row">

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="event-desc text-left">
                      <h4 class="card-title">
                        الجمعية تجهز سلة وقائية لتوزيعها على مرضى الفشل الكلوي
                      </h4>

                      <a class="more" routerLink="/articles/727">
                        إقرأ المزيد
                        <i class="fa fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card2">
              <div class="card-img-top" style="background-image: url(assets/images/logo2.png);"></div>
              <div class="card-body ">
                <div class="row">

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="event-desc text-left">
                      <h4 class="card-title">
                        “كلانا” تنفّذ 78 ألف جلسة “غسيل كلى” سنويًّا بتكلفة 43 مليون ريال </h4>

                      <a class="more" routerLink="/articles/732">
                        إقرأ المزيد
                        <i class="fa fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div> -->
    </div>
    <!-- <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card2">
              <div class="card-img-top" style="background-image: url(assets/images/latestnews5.jpeg);"></div>
              <div class="card-body ">
                <div class="row">

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="event-desc text-left">
                      <h4 class="card-title">
                        «هدف» و «كلانا» يبرمان اتفاقية تعاون للتدريب والتوظيف </h4>

                      <a class="more" routerLink="/articles/716">
                        إقرأ المزيد
                        <i class="fa fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card2">
              <div class="card-img-top" style="background-image: url(assets/images/latestnews4.jpeg);"></div>
              <div class="card-body ">
                <div class="row">

                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="event-desc text-left">
                      <h4 class="card-title">
                        بمناسبة اليوم العالمي للكلى وفد من شركة ميدغلف يقوم بزيارة جمعية كلانا </h4>

                      <a class="more" routerLink="/articles/729">
                        إقرأ المزيد
                        <i class="fa fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div> -->
  </div>
</div>
