import { Component, Input, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';


@Component({
  selector: 'app-home-waqf',
  templateUrl: './home-waqf.component.html',
  styleUrls: ['./home-waqf.component.scss']
})
export class HomeWaqfComponent implements OnInit {

  @Input() waqf_list;

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    // observer: true,
    // keyboard: true,
    // mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    effect: 'slide',
    speed: 1500,
  };


  constructor() { }

  ngOnInit(): void {

  }

}
