<div class="row">
    <div class="col-md-7 col-sm-12 col-xs-12">
        <h6 class="main-title">تقوم رؤية الجمعية على مبدأ التمويل الذاتي ولذا عملت على إنشاء أوقاف للصرف على برامجها ما أمكن وحتى الاكتفاء التام بدلا من الاعتماد على التبرعات فقط وذلك لتأمين الاستدامة في الإيرادات</h6>
        <p>مشروع إنشاء مبنيين استثماريين للجمعية</p>
        <p>المشروع عبارة عن موقعين في الرياض حي السفارات، يقع الموقع الأول بجانب السفارة الكورية والموقع الثاني بجانب مبنى الأمن الدبلوماسي.</p>
        <p>يتكون الموقع الأول من قبو بمساحة 7,500 متر مربع، يتوضع علية مبنيين من ثلاث طوابق، وقد تم تقسيم الطوابق إلى بهو وممرات ومكاتب للتأجير.</p>
        <p>يتكون الموقع الثاني من قبو بمساحة 14,350 متر مربع، يتوضع علية ثلاث مباني من ثلاث طوابق وطابق وسطي، وقد تم تقسيم الطوابق إلى بهو وممرات ومعارض ومكاتب للتأجير.</p>
    </div>
    <div class="col-md-5 col-sm-12 col-xs-12">
        <div class=" swiper">
            <div class="swiper-container" *ngIf="slides.length" [swiper]="config">
                <div class="swiper-wrapper">
                    <div *ngFor="let slide of slides; index as i" class="swiper-slide">
                        
                        <div class="slide-img">
                            <img  [src]="slide.image">

                        </div>

                        
                    </div>
    
                    
                </div>
                <div class="swiper-pagination" slot="pagination" ></div>
    
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div>
        </div>
    </div>
</div>