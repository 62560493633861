import { Component, OnInit } from '@angular/core';
import { PartenersService } from '../../services/parteners.service';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss']
})
export class OthersComponent implements OnInit {

  // other list empty object
  others_List: any[] = [];

  pageSize: number = 10;
  totalItems: number = 0;
  currentPage: number = 1;

  constructor(
    private service: PartenersService
  ) { }

  ngOnInit(): void {
    this.getOther();
  }

  // get other list data from api
  getOther() {
    this.service.getOtherList(this.currentPage).subscribe((res: any) => {
      // push other list data to object
      this.others_List = res.content;
    })
  }

}
