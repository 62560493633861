<div class="full-width">
  <div class="row page-title">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <h3>رؤساء اللجان</h3>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12">
      <nav aria-label="breadcrumb" class="titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
          <li class="breadcrumb-item" style="padding-left: 0px">
            <a routerLink="about/coc" style="padding-right: 10px">
              رؤساء اللجان</a
            >
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div *ngFor="let item of COC; let i = index">
  <div class="row grey-bg">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <h6 class="title">{{ item.title }}</h6>
        </div>
      </div>
    </div>
  </div>

  <div class="row grey-bg">
    <div class="container">
      <!-- make for loop to get COC managers from COC categories -->
      <div *ngFor="let x of item.categories">
        <div *ngIf="x.category == 'رؤساء اللجان'" class="row padd">
          <div class="col-md-3 col-sm-12 col-xs-12" style="text-align: right">
            <img class="family-img" [src]="x.image" />
          </div>
          <div class="col-md-9 col-sm-12 col-xs-12" style="text-align: right">
            <h5>{{ x.value }}</h5>
            <p class="grey-paragraph">{{ x.content }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <mat-accordion
          class="example-headers-align"
          multi
        >
          <mat-expansion-panel class="mat-elevation-z0" style="background-color: #fbfbfb !important">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h6 class="text-dark">مهام اللجنة</h6>
              </mat-panel-title>

              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <div
                class="col-md-6 col-sm-12 col-xs-12 mar-bottom"
                *ngFor="
                  let cat of item?.categories.slice(1, 100);
                  let i = index
                "
              >
                <!-- view tasks data if category for tasks and not for managers -->
                <div
                  *ngIf="cat.category !== 'رؤساء اللجان'"
                  class="card2"
                  style="background: #fbfbfb"
                >
                  <div class="beside">
                    <h6 class="stratigic-number">{{ i + 1 }}</h6>
                    <h6 style="margin-top: 15px">
                      {{ cat.value }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
