import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-donate-popup',
  templateUrl: './donate-popup.component.html',
  styleUrls: ['./donate-popup.component.scss']
})
export class DonatePopupComponent implements OnInit {

  opened=true;
  @Output() closePopup = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
     // add listener to disable scroll
     window.addEventListener('scroll', this.noScroll);
  }
  close(event)
  {
    this.opened=false; 

    event.preventDefault();
     // Remove listener to re-enable scroll
     window.removeEventListener('scroll', this.noScroll);
 
   }
   valueChanged() { 
    this.opened=false;
    this.closePopup.emit(this.opened);

  }
  noScroll() {
    window.scrollTo(0, 0);
  }

}
