<div class="full-width">
  <div class="row page-title">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <h3>التعريف بالجمعية</h3>
    </div>

    <div class="col-md-6 col-sm-6 col-xs-12">
      <nav aria-label="breadcrumb" class="titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">الرئيسية</a></li>
          <li class="breadcrumb-item" style="padding-left: 0px">
            <a routerLink="about/about-us" style="padding-right: 10px">
              التعريف بالجمعية
            </a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row justify-content-center py-5">
    <div class="col-md-10 col-sm-8 col-xs-8">
      <div class="row">
        <!-- make for loop to view all real items data from api -->
        <div *ngFor="let item of whoWeAre" class="col-md-4 col-sm-12 col-xs-12">
          <div class="card text-center">
            <img src="{{ item.image }}" />
            <h2 class="main-title text-center text-dark">{{ item.title }}</h2>
            <p class="card-body">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-md-10 col-sm-10 col-xs-10">
      <div class="tabs-container">
        <input
          style="display: none"
          type="radio"
          id="tab3"
          name="tab"
          checked
        />
        <label class="tab-label text-secondary font-weight-bold" for="tab3"
          >رئيس مجلس الإدارة</label
        >
        <input style="display: none" type="radio" id="tab2" name="tab" />
        <label class="tab-label text-secondary font-weight-bold" for="tab2"
          >إدارة الجمعية</label
        >
        <input style="display: none" type="radio" id="tab1" name="tab" />
        <label class="tab-label text-secondary font-weight-bold" for="tab1"
          >أمناء سابقين للجمعية</label
        >

        <div class="content-container clearfix">
          <div class="content" id="c3">
            <div class="container-fluid">
              <div class="row" *ngFor="let item of management">
                <!-- king -->
                <div
                  *ngIf="item.description == 'أول رئيس مجلس إدارة'"
                  class="col-md-12 col-sm-12 col-xs-12 manager-bg"
                >
                  <div class="row justify-content-center">
                    <div class="col-md-6">
                      <div class="home-article">
                        <a
                          class="cover-img"
                          [ngStyle]="{
                            'background-image': 'url(' + item.image + ')'
                          }"
                        >
                          <div class="home-article-desc text-center">
                            <h4 class="card-title">
                              {{ item.title }}
                            </h4>
                            <p class="card-desc">{{ item.description }}</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-5" *ngFor="let item of management">
                <!-- other manager -->
                <div
                  *ngIf="item.description == 'رئيس مجلس الادارة'"
                  class="col-md-12 col-sm-12 col-xs-12 manager-bg"
                >
                  <div class="row justify-content-center">
                    <div class="col-md-6">
                      <div class="home-article">
                        <a
                          class="cover-img"
                          [ngStyle]="{
                            'background-image': 'url(' + item.image + ')'
                          }"
                        >
                          <div class="home-article-desc text-center">
                            <h4 class="card-title">
                              {{ item.title }}
                            </h4>
                            <p class="card-desc">{{ item.description }}</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content" id="c2">
            <div class="row" *ngFor="let item of management">
              <div
                *ngIf="
                  item.description == 'الرئيس التنفيذ' ||
                  item.description == 'الرئيس التنفيذي'
                "
                class="col-md-12 col-sm-12 col-xs-12 manager-bg"
              >
                <div class="row justify-content-center">
                  <div class="col-md-6">
                    <div class="home-article">
                      <a
                        class="cover-img"
                        [ngStyle]="{
                          'background-image': 'url(' + item.image + ')'
                        }"
                      >
                        <div class="home-article-desc text-center">
                          <h4 class="card-title">
                            {{ item.title }}
                          </h4>
                          <p class="card-desc">{{ item.description }}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="container">
            <h6 style="text-align: right; margin-bottom: 30px">
              يتم إدارة الجمعية من خلال الجمعية العمومية بإلاضافة إلي مجلس
              الإدارة و بعض اللجان المتعددة :
              <span class="red-color">
                اللجنة التنفيذية ، اللجنة الصحية و الاجتماعية ، اللجنة الإعلامية
                ، و اللجنة الشرعية</span
              >
            </h6>
          </div> -->
          </div>
          <div class="content" id="c1">
            <div class="container-fluid">
              <div class="row justify-content-center manager-bg">
                <div
                  class="col-md-5 col-sm-12 col-xs-12"
                  *ngFor="let item of formers"
                >
                  <div class="home-article">
                    <a
                      class="cover-img"
                      [ngStyle]="{
                        'background-image': 'url(' + item.image + ')'
                      }"
                    >
                      <div class="home-article-desc text-center">
                        <h4 class="card-title">
                          {{ item.title }}
                        </h4>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
