import { Component, OnInit, Input } from '@angular/core';
import { donationPlan } from '../../models/donation-plan';

@Component({
  selector: 'app-donation-card',
  templateUrl: './donation-card.component.html',
  styleUrls: ['./donation-card.component.scss']
})
export class DonationCardComponent implements OnInit {
  @Input() donationPlan:donationPlan;
  constructor() { }

  ngOnInit(): void {
  }

}
