import { AuthGuardService } from "./shared/shared_services/auth-guard.service";
import { RouterModule, Routes } from "@angular/router";

import { AboutComponent } from "./general-pages/pages/about/about.component";
import { CeoMessageComponent } from "./general-pages/pages/ceo-message/ceo-message.component";
import { ChangePasswordComponent } from "./authentication/pages/change-password/change-password.component";
import { Component2Component } from "./cms-general-pages/components/component2/component2.component";
import { ContactComponent } from "./general-pages/pages/contact/contact.component";
import { FriendlyWebsiteComponent } from "./general-pages/pages/friendly-website/friendly-website.component";
import { HomeComponent } from "./general-pages/pages/home/home.component";
import { InternetServicesComponent } from "./internet-services/internet-services.component";
import { LibraryComponent } from "./general-pages/pages/library/library.component";
import { LoginPageComponent } from "./authentication/pages/login-page/login-page.component";
import { NgModule } from "@angular/core";
import { OrderByIdComponent } from "./general-pages/pages/order-by-id/order-by-id.component";
import { PartnersComponent } from "./general-pages/pages/partners/partners.component";
import { PrivacyPolicyComponent } from "./general-pages/pages/privacy-policy/privacy-policy.component";
import { ProductsComponent } from "./general-pages/pages/products/products.component";
import { ProfileComponent } from "./authentication/pages/profile/profile.component";
import { RegisterVerificationComponent } from "./register-verification/register-verification.component";
import { RegistrationComponent } from "./authentication/pages/registration/registration.component";
import { ResetPassword1Component } from "./authentication/pages/reset-password1/reset-password1.component";
import { ResetPassword2Component } from "./authentication/pages/reset-password2/reset-password2.component";
import { ResetPasswordComponent } from "./authentication/pages/reset-password/reset-password.component";
import { SearchComponent } from "./general-pages/pages/search/search.component";
import { TeamComponent } from "./general-pages/pages/team/team.component";
import { TermsComponent } from "./general-pages/pages/terms/terms.component";
import { LearnMoreComponent } from './general-pages/pages/learn-more/learn-more.component';
import { PatientRightsComponent } from './general-pages/pages/patient-rights/patient-rights.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "", redirectTo: "home", pathMatch: "full" },

  { path: "home", component: HomeComponent },
  { path: "registration", component: RegistrationComponent },
  { path: "Email-Verification", component: RegisterVerificationComponent },
  { path: "login", component: LoginPageComponent },
  { path: "search", component: SearchComponent },
  { path: "staticPages", component: InternetServicesComponent },

  { path: "reset-password", component: ResetPasswordComponent },
  { path: "reset-password1", component: ResetPassword1Component },
  { path: "new-password", component: ResetPassword2Component },
  { path: "profile", component: ProfileComponent },

  { path: "change-password", component: ChangePasswordComponent },

  { path: "Team", component: TeamComponent },
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },
  { path: "partners", component: PartnersComponent },
  { path: "ceo_msg", component: CeoMessageComponent },
  { path: "team", component: TeamComponent },
  { path: "library", component: LibraryComponent },
  { path: "friendly-websites", component: FriendlyWebsiteComponent },
  { path: "products", component: ProductsComponent },
  { path: "orders/:id", component: OrderByIdComponent },

  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "terms&conditions", component: TermsComponent },
  { path: "general-pages", component: Component2Component },

  { path: "learn-more", component: LearnMoreComponent },
  { path: "patient-rights", component: PatientRightsComponent },


  {
    path: "articles",
    loadChildren: () =>
      import("./articles/articles.module").then((m) => m.ArticlesModule),
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./statements/statements.module").then((m) => m.StatementsModule),
  },
  {
    path: "recruitment",
    loadChildren: () =>
      import("./recruitment/recruitment.module").then(
        (m) => m.RecruitmentModule
      ),
  },
  {
    path: "cms",
    loadChildren: () => import("./cms/cms.module").then((m) => m.CmsModule),
  },
  {
    path: "cmsforms",
    loadChildren: () =>
      import("./cms-forms/cms-forms.module").then((m) => m.CmsFormsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "cmspages",
    loadChildren: () =>
      import("./cms-general-pages/cms-general-pages.module").then(
        (m) => m.CmsGeneralPagesModule
      ),
  },

  {
    path: "about",
    loadChildren: () =>
      import("./about/about.module").then((m) => m.AboutModule),
  },
  {
    path: "awqaf",
    loadChildren: () =>
      import("./awqaf/awqaf.module").then((m) => m.AwqafModule),
  },
  {
    path: "partener",
    loadChildren: () =>
      import("./parteners/parteners.module").then((m) => m.PartenersModule),
  },
  {
    path: "programs",
    loadChildren: () =>
      import("./programs/programs.module").then((m) => m.ProgramsModule),
  },
  {
    path: "volunteers",
    loadChildren: () =>
      import("./volunteering/volunteering.module").then(
        (m) => m.VolunteeringModule
      ),
  },
  {
    path: "forms",
    loadChildren: () => import("./form/form.module").then((m) => m.FormModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
