import { AppInfoService } from "./shared/shared_services/app-info.service";
import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { HttpClient } from "@angular/common/http";
import { CommonGeneralService } from "./general-pages/pages/services/common-general.service";

declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "etaam-website";
  SocialLinks: any = {};

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private commonGeneralService: CommonGeneralService,
    private http: HttpClient,
    private appInfoService: AppInfoService
  ) {
    if (sessionStorage.getItem("etaam_lang"))
      translateService.setDefaultLang(sessionStorage.getItem("etaam_lang"));
    else translateService.setDefaultLang("ar");

    this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });

    this.appInfoService.currentUser.subscribe(() => {
      let username = localStorage.getItem("username");
      let token = localStorage.getItem("login_token");
      if (username) {
        this.appInfoService.currentUser.next(username);
      }
      if (token) {
        this.appInfoService.currentToken.next(token);
      }
    });
    localStorage.setItem("APP_ID", "188");
  }

  ngOnInit() {
    // this.http.get('https://jsonplaceholder.typicode.com/comments')
    //   .subscribe((r) => {
    //     
    //   });
    this.commonGeneralService.getFooterSocialLinks().subscribe((res: any) => {
      this.SocialLinks = res.content;
    });
  }
  openURL() {
    window.location.replace("https:/donate.kellana.org.sa");
    // window.open("https:/donate.kellana.org.sa","_blank")
  }
}
