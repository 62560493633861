import { HttpHeaders } from '@angular/common/http';
import { BaseHttpService } from "src/app/services/base-http.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class VolunteeringService {

  header: HttpHeaders = new HttpHeaders();

  constructor(
    private http: BaseHttpService
  ) { }

  // get volunteer data
  getVolunteerText() {
    return this.http.post("/api/cms", {
      module_id: 2909,
      application_id: 188,
      lang_id: 2
    });
  }

  // get categories lists
  getCategories() {
    return this.http.post("/api/categories/multiCategories", {
      module_id: 2910,
      application_id: 188,
      lang_id: 2
    })
  }

  // get countries lists
  getCountries() {
    return this.http.post("/api/countries", {
      application_id: 188,
      lang_id: 2
    })
  }

  // get cities lists
  getCities() {
    return this.http.post("/api/cities", {
      application_id: 188,
      lang_id: 2
    })
  }

  // call api to submit volunteer form
  submitVolunteerForm(body) {
    let setHeaders = this.header.set("access-token", localStorage.getItem('login_token'));
    let obj = {
      ...body,
      application_id: 188,
      lang_id: 2
    }
    return this.http.post("/api/cms/store", obj, { headers: setHeaders });
  }

  // call api to get volunteer status
  getVolunteerStatus() {
    let setHeaders = this.header.set("email", localStorage.getItem('email'));
    return this.http.post("/api/cms/myVolunteer", {
      module_id: 2910,
      application_id: 188,
      lang_id: 2
    },
      { headers: setHeaders })
  }

  // call api to get volunteer status search
  getVolunteerStatusSearch(search) {
    let setHeaders = this.header.set("email", localStorage.getItem('email'));
    let user_id = localStorage.getItem('User_ID');
    return this.http.post("/api/cms/myVolunteer", {
      module_id: 2910,
      application_id: 188,
      created_by: user_id,
      lang_id: 2,
      title: search
    },
      { headers: setHeaders });
  }

  // call api to get one volunteer data
  getVolunteer(id) {
    return this.http.post("/api/cms/edit", {
      id: id,
      lang_id: 2,
      application_id: 188,
      module_id: 2910
    })
  }

  // update volunteer
  updateVolunteer(body, id) {
    let setHeaders = this.header.set("access-token", localStorage.getItem('login_token'));
    return this.http.post(
      "/api/cms/update",
      {
        ...body,
        id: id,
        lang_id: 2,
        application_id: 188,
        module_id: 2910

      },
      { headers: setHeaders }
    );
  }
}
