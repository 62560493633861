<div class="full-width">
  <div class="page-title">
    <div class="container">
      <h3>
        استعادة كلمة المرور
      </h3>
    </div>
  </div>
</div>
<div class="register">
  <div class="container">
    <form [formGroup]="registrationForm" (ngSubmit)="register(registrationForm.value)">
      <fieldset _ngcontent-dyb-c300="" class="scheduler-border">
        <legend _ngcontent-dyb-c300="" class="scheduler-border wizard--title">
          {{'AUTH.REGISTER.REGISTRATION'|translate}} </legend>

        <div class="row">
          <!--E-mail-->
          <div class="col-md-12 col-sm-12 col-xs-12 text-left">
            <div class="form-group">
              <label class="font--bold">{{'AUTH.REGISTER.EMAIL'|translate}}<span class="required">*</span></label>
              <input type="email" class="form-control" placeholder="{{'AUTH.REGISTER.EMAIL'|translate}}"
                formControlName="email">
              <!--Validation-->
              <span *ngIf="registrationForm.get('email').touched && registrationForm.get('email').invalid">
                <small *ngIf="registrationForm.get('email').errors.required" class="form-text required">
                  {{'VALIDATORS.REQUIRED'|translate}}
                </small>
                <small *ngIf="registrationForm.get('email').errors.pattern" class="form-text required">
                  {{'VALIDATORS.EMAIL_PATTERN'|translate}}
                </small>
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 text-left">
            <button class="btn-custom" type="submit">{{'AUTH.REGISTER.SEND'|translate}}</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>

</div>
