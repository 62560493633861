
<div class="card bg-light border-secondary mb-">
    <div class="card-header">
        <h6 class="card-title pull-left">{{opportunityDetails.title}} </h6>
        <button class="pull-right btn-custom"> {{'OPPORTUNITY.APPLY_NOW'|translate}}</button>
    </div>
    <div class="card-body text-left">
        <div class="row">
            <div class="col-md-12  text-justify">
                <p> <span class="data ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna.
                </span></p>
            </div>
        </div>
         <div class="row">
            <div class="col-md-6">
                <p>{{'OPPORTUNITY.AVAILABLE'|translate}}: <span class="data">{{opportunityDetails.available}}</span></p>
                <p>{{'OPPORTUNITY.GENDER'|translate}}: <span class="data">{{opportunityDetails.gender}}</span></p>
                <p>{{'OPPORTUNITY.MIN_AGE'|translate}}: <span class="data">{{opportunityDetails.min_age}}</span></p>
               </div>

               <div class="col-md-6">
                <p><i class="fa fa-calendar"></i>&nbsp;  <span class="data">{{opportunityDetails.date}}</span></p>
                <p><i class="fa fa-hourglass-start"></i>&nbsp;  <span class="data">{{opportunityDetails.time}}</span></p>
                <p><span class="label label-success">{{opportunityDetails.status}}</span></p>


            </div>
         </div>

         <div class="row">
            <div class="col-md-12  text-justify">
               <p><i class="fa fa-map-marker"></i>&nbsp; <span class="data">{{opportunityDetails.location}}</span></p>
            </div>
        </div>
    </div>
    <div *ngIf="!fromEvent" class="card-footer">
        <span class="data pull-left"> {{'OPPORTUNITY.EVENT_NAME'|translate}}: {{opportunityDetails.event_name}}</span>
        <a class="more pull-right" routerLink="Opportunities/Event_Details"> {{'OPPORTUNITY.VIEW_EVENT'|translate}} <i class="fa fa-arrow-circle-right"></i></a>

    </div>
</div>
