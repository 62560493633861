import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-cards3',
  templateUrl: './button-cards3.component.html',
  styleUrls: ['./button-cards3.component.scss']
})
export class ButtonCards3Component implements OnInit {
  @Input() programs;
  constructor() { }

  ngOnInit(): void {

  }

}
