<div class="popup" *ngIf="opened">
    <span class="close" (click)="close($event);valueChanged()">
        <i class="fa fa-times"></i>
      </span>
      <div class="content">
          <h2>
              Are you Sure that you want to delete this Event ?
          </h2>
          <p>If you want to delete this event , you can't return it again !
              
          </p>
          <button class="btn btn-xs btn-custom new"  >Yes, Iam sure</button>
          <button class="btn btn-xs btn-custom new" style="color: black !important; background-color: #e9ecef !important;" (click)="close($event);valueChanged()"  >cancel</button>


      </div>
</div>
<div class="style" *ngIf="opened"></div>