<div>
    <div class="login_bar">
        <h2>{{ "PROFILE.PROFILE" | translate }}</h2>
    </div>
    <div class="wrapper">
        <div class="empty_wrapper">
            <img src="../../../../assets/images/User_Profile.svg" />
        </div>
        <div class="form_wrapper">
            <form [formGroup]="ProfileForm" (ngSubmit)="submitForm()">
                <h3>{{ "PROFILE.PERSONAL_INFO" | translate }}</h3>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="username" required [placeholder]="('AUTH.REGISTER.FULL_NAME' | translate) + '*'" />
                    <div *ngIf="
              ProfileForm.get('username').touched &&
              ProfileForm.get('username').invalid
            ">
                        <div *ngIf="ProfileForm.get('username').errors.required" class="text-right validator-error">
                            {{ "VALIDATORS.REQUIRED" | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="email" required [placeholder]="('AUTH.REGISTER.EMAIL' | translate) + '*'" />
                    <div *ngIf="
              ProfileForm.get('email').touched &&
              ProfileForm.get('email').invalid
            ">
                        <div *ngIf="ProfileForm.get('email').errors.required" class="text-right validator-error">
                            {{ "VALIDATORS.REQUIRED" | translate }}
                        </div>
                        <div *ngIf="
                ProfileForm.get('email').invalid &&
                !ProfileForm.get('email').errors.required
              " class="text-right validator-error">
                            {{ "VALIDATORS.EMAIL_PATTERN" | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="mobile" required [placeholder]="('AUTH.REGISTER.PHONE_NUMBER' | translate) + '*'" />
                    <div *ngIf="
              ProfileForm.get('mobile').touched &&
              ProfileForm.get('mobile').invalid
            ">
                        <div *ngIf="ProfileForm.get('mobile').errors.required" class="text-right validator-error">
                            {{ "VALIDATORS.REQUIRED" | translate }}
                        </div>
                        <div *ngIf="
                ProfileForm.get('mobile').invalid &&
                !ProfileForm.get('mobile').errors.required
              " class="text-right validator-error">
                            {{ "VALIDATORS.mobile" | translate }}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="zip_code" [placeholder]="'PROFILE.POSTAL_CODE' | translate" />
                    <!-- <div *ngIf="
              ProfileForm.get('zip_code').touched &&
              ProfileForm.get('zip_code').invalid
            ">
            <div *ngIf="
                ProfileForm.get('zip_code').invalid &&
                !ProfileForm.get('zip_code').errors.required
              " class="text-right validator-error">
              {{ "VALIDATORS.zip_code" | translate }}
            </div>
          </div> -->
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="address" [placeholder]="'PROFILE.AREA' | translate" />
                </div>

                <button class="btn btn-xs btn-custom-sec new" type="submit">
          {{ "AUTH.NEW_PASSWORD.save" | translate }}
        </button>

                <a routerLink="/change-password">
                    <h3 class="mt-4">{{ "AUTH.NEW_PASSWORD.changePassword" | translate }}</h3>
                </a>

            </form>
        </div>
    </div>
</div>
