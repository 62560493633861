import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';


@Component({
  selector: 'app-slider-title-description-card',
  templateUrl: './slider-title-description-card.component.html',
  styleUrls: ['./slider-title-description-card.component.scss']
})
export class SliderTitleDescriptionCardComponent implements OnInit {


  slides: any[] = [];

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    
    navigation: true,
    pagination: this.pagination,
    // grabCursor: true,     
    
    effect: 'slide',
    speed:1500,
  };
  

  constructor() { }

  ngOnInit(): void {
    this.slides=[
      {'image':'../../../assets/images/awqaf1.jpg'},
      {'image':'../../../assets/images/awqaf2.jpg'},
      {'image':'../../../assets/images/awqaf3.jpg'},
      {'image':'../../../assets/images/awqaf4.jpg'},
      {'image':'../../../assets/images/awqaf5.jpg'},
      {'image':'../../../assets/images/awqaf6.jpg'},
      {'image':'../../../assets/images/awqaf7.jpg'},
      {'image':'../../../assets/images/awqaf8.jpg'},


    ]
  }

}
