<div class="slider">
  <div class="swiper">
    <div class="swiper-container" [swiper]="config">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let slide of slides; index as i">
          <div class="row">
            <div class="col-md-5 col-sm-12 col-xs-12 slid-img hide-large">
              <img class="program-img" src="{{ slide.image }}" alt="slider-image" />
            </div>
            <div class="col-md-7 col-sm-12 col-xs-12 slide-text">
              <h3 class="main-title text-dark">{{ slide.title }}</h3>
              <p class="main-paragraph">
                {{ slide.content | textFormatter }}
              </p>
              <button class="red-btn" routerLink="../programs/{{ slide.id }}">قدم الآن</button>
            </div>
            <div class="col-md-5 col-sm-12 col-xs-12 slid-img hide-xs">
              <img class="program-img" src="{{ slide.image }}" alt="slider-image" />
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-pagination" slot="pagination"></div>

      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</div>


<div class="slider">
  <!-- <p-carousel >

  </p-carousel> -->

</div>
